import {Badge, Button, Col, Form, InputGroup, Table} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {IoPeopleCircleOutline, IoSettings,} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateProfile, GetProfile, LoadAvatarList, UpdateAvatar, UpdateProfile} from "./ProfileService";
import {DetailsSection, RequiredFieldsAreValid} from "../../generators/DetailsGenerator";
import ProfileMetaData from "./ProfileMetaData";
import {Label} from "reactstrap";
import {customDatePickerHeader, transformToDatepickerFormat, transformToLocalDate} from "../../util/ResourceService";
import DatePicker from "react-datepicker";
import {InfoModal} from "../../generators/ModalGenerator";
import defaultLogo from "../../../assets/images/tiger_logo.png";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class ProfileDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            accountId: this.props.match.params.accountId,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            profile: {
                id: null,
                name: null,
                birthday: null,
                gender: "UNDEFINED",
                defaultProfile: false,
                ageRange: {
                    min: 0,
                    max: 0
                },
                _embedded: {
                    account: {
                        id: Number(this.props.match.params.accountId)
                    },
                    preferences: []
                }
            },
            avatarList: [],
            preferences: [],
            avatar: null,
            originalAvatar: null,
            originalProfile: {},
            showAvatarList: false,
            sections: {
                general: true,
                customerDetails: true,
                customerSettings: true,
                preferences: true
            },
            validationErrors: []
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        await this.loadProfile();
    }

    //--------
    //Loading
    //--------

    async loadProfile() {
        let loadedProfile = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedProfile = await GetProfile(this.state.id);

            if (!loadedProfile.error) {
                const profile = loadedProfile.result;

                let preferences = (profile._embedded != null && profile._embedded.preferences != null) ? profile._embedded.preferences : [];
                let avatar = (profile._links != null && profile._links.avatar != null && profile._links.avatar.href != null) ?
                    profile._links.avatar.href : null;

                let avatarList = await LoadAvatarList();

                if (avatarList.error) {
                    console.warn("Live avatar list could not be loaded from cdn. Local fallback is used");
                }

                this.setState(prevState => ({...prevState,
                    id: this.state.id,
                    editMode: this.state.editMode,
                    profile: {
                        ...prevState.profile,
                        ...profile},
                    originalProfile: {...prevState.profile, ...profile},
                    preferences: preferences,
                    avatar: avatar,
                    originalAvatar: avatar,
                    avatarList: avatarList.result
                }));
            } else {
                this.setState(prevState => ({...prevState, error: loadedProfile}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        const labelStyle = {
            fontSize: "20px",
            backgroundColor: "#C8C8C8",
            borderStyle: "solid",
            borderColor: "#cdd7e0",
            borderWidth: "1px",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%"
        };

        return (
            <>
                <div className="details-title">
                    {this.state.profile.id != null ? "Details of Profile " + this.state.profile.id : "Create a new Profile"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{
                        pathname: "/" + GlobalConstants.APP_PATH + "accounts/" + this.state.accountId,
                        state: this.state.flowState
                    }}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.profile.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}
                            type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        profile: this.state.originalProfile
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateProfile()} />
                    }

                    <Button style={{float: "right", marginTop: "5px"}}
                            variant={this.state.editMode.expertMode ? "secondary" : "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    expertMode: !this.state.editMode.expertMode
                                }
                            }))}>Expert Mode</Button>
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}}
                            variant={this.state.editMode.autoSave ? "secondary" : "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                    <div style={{float: "right", marginRight: "50px", fontSize: "20px", marginTop: "8px"}}>
                        <Form.Check type="checkbox" style={{display: "inline-block"}}
                                    disabled={!this.state.editMode.active}
                                    label={"Default Profile"}
                                    value={this.state.profile.defaultProfile}
                                    checked={this.state.profile.defaultProfile}
                                    onChange={(e) => this.setState(prevState => ({
                                        ...prevState,
                                        profile: {
                                            ...prevState.profile,
                                            defaultProfile: !this.state.profile.defaultProfile
                                        }
                                    }))}
                        />
                    </div>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="profile"
                        fields={ProfileMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="general"
                        onUpdateResource={() => this.saveOrUpdateProfile()}
                    />

                    {/* CUSTOMER DETAILS */}
                    <DetailsSection
                        nameInState="profile"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="customerDetails"
                        onUpdateResource={() => this.saveOrUpdateProfile()}
                        label={<span><IoPeopleCircleOutline/>&#xA0;Customer Details</span>}
                    />

                    {this.state.sections.customerDetails &&
                    <Form>
                        <Row>
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Birthday</Label>
                                <DatePicker wrapperClassName="datePicker"
                                            todayButton={"Today"}
                                            dateFormat={"yyyy-MM-dd"}
                                            renderCustomHeader={({
                                                                     date,
                                                                     changeYear,
                                                                     changeMonth,
                                                                     decreaseMonth,
                                                                     increaseMonth,
                                                                     prevMonthButtonDisabled,
                                                                     nextMonthButtonDisabled
                                                                 }) => customDatePickerHeader(new Date(date), changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled)}
                                            selected={transformToDatepickerFormat(this.state.profile.birthday)}
                                            onChange={(date) => this.setState(prevState => ({
                                                ...prevState,
                                                profile: {
                                                    ...prevState.profile,
                                                    birthday: transformToLocalDate(date.toString())
                                                }
                                            }))}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Avatar</Label>
                                <InputGroup>
                                    <Form.Control type="text"
                                                  disabled={true}
                                                  value={this.state.avatar}/>
                                    {this.state.editMode.active &&
                                        <Button variant="outline-secondary" onClick={() => this.setState(prevState => ({
                                            ...prevState,
                                            showAvatarList: true
                                        }))}>Edit</Button>
                                    }
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Age Range (from)</Label>
                                <Form.Control type="number" value={this.state.profile.ageRange.min}
                                              disabled={!this.state.editMode.active}
                                              onChange={(e) => this.setState(prevState => ({
                                                  ...prevState,
                                                  profile: {
                                                      ...prevState.profile,
                                                      ageRange: {
                                                          ...prevState.profile.ageRange,
                                                          min: e.target.value
                                                      }
                                                  }
                                              }))}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Age Range (to)</Label>
                                <Form.Control type="number" value={this.state.profile.ageRange.max}
                                              disabled={!this.state.editMode.active}
                                              onChange={(e) => this.setState(prevState => ({
                                                  ...prevState,
                                                  profile: {
                                                      ...prevState.profile,
                                                      ageRange: {
                                                          ...prevState.profile.ageRange,
                                                          max: e.target.value
                                                      }
                                                  }
                                              }))}/>
                            </Form.Group>
                        </Row>
                    </Form>
                    }

                    {/* PREFERENCES */}
                    <DetailsSection
                        nameInState="profile"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="preferences"
                        onUpdateResource={() => this.saveOrUpdateProfile()}
                        label={<span><IoSettings/>&#xA0;Preferences</span>}
                    />

                    {this.state.sections.preferences &&
                    <Table bordered striped responsive>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Visible</th>
                            <th>Cover</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.preferences.map(p => (
                            <tr role="row" key={p.id}>
                                <td><Link to={"/" + GlobalConstants.APP_PATH + "clusters/" + p.id}
                                          style={{fontStyle: "italic", color: "#333"}}>{p.id}</Link></td>
                                <td>{p.name}</td>
                                <td><Badge bg={p.type === "TOPIC" ? "success" : "danger"}>{p.type}</Badge></td>
                                <td><Badge bg={p.visible ? "success" : "danger"}>{p.visible ? "Y" : "N"}</Badge>
                                </td>
                                <td>
                                    <img src={this.getClusterCover(p)} alt={"Cover of cluster " + p.id} width={100}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{
                        pathname: "/" + GlobalConstants.APP_PATH + "accounts/" + this.state.accountId,
                        state: this.state.flowState
                    }}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateProfile()} />
                    }
                </div>

                <InfoModal show={this.state.showAvatarList} onHide={() => this.setState(prevState => ({...prevState, showAvatarList: false}))}
                           title="Select an avatar" body={this.avatarListDialog()} />

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //--------
    // Helpers
    //--------

    getClusterCover(cluster) {
        if (cluster._links != null && cluster._links.icon != null && cluster._links.icon.href != null) {
            return cluster._links.icon.href;
        } else {
            return defaultLogo;
        }
    }
    //------------
    //API Methods
    //------------

    async saveOrUpdateProfile() {
        if (!RequiredFieldsAreValid('profile', ProfileMetaData.DETAILS_GENERAL, this.state, (s) => this.setState(s))) {
            return;
        }
        let saved = {};
        let profile = this.state.profile;
        if(this.state.profile.id != null) {
            saved = await UpdateProfile(this.state.accountId, profile);
        } else {
            saved = await CreateProfile(this.state.accountId, profile);
        }

        if (!saved.error) {
            if (this.state.profile.id == null) {
                this.props.history.push("/"+ GlobalConstants.APP_PATH + "accounts/" + this.state.accountId + "/profiles/" + saved.result.id);
            }
            let result = saved.result;
            result.preferences = (result._embedded != null && result._embedded.preferences != null ) ? result._embedded.preferences : [];
            this.setState((prevState) =>({...prevState, profile: result, editMode: {...prevState.editMode, active: this.state.editMode.autoSave}}));

            this.props.addToast("The profile has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: saved}));
        }
    }

    async updateAvatar(newAvatar) {
        if (newAvatar !== this.state.avatar) {
            let update = await UpdateAvatar(this.state.id, newAvatar);
            if (update.error) {
                this.setState(prevState => ({...prevState, error: update}));
            } else {
                this.props.addToast("The avatar has been updated successfully", {
                    autoDismiss: true,
                    appearance: "success"
                });
            }
        }
        this.setState(prevState => ({...prevState, avatar: newAvatar, showAvatarList: false, editMode: {...prevState.editMode, active: false}}));
    }



    //--------
    // DIALOGS
    //--------

    avatarListDialog() {
        if (this.state.avatarList.default == null) {
            return null;
        }
        return (
            <>
                <h5>Default</h5>
                <Table responsive bordered hover striped>
                    <thead>
                        <tr>
                            <th>Preview</th>
                            <th>Name</th>
                            <th>URL</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{cursor: "pointer"}} onClick={() => this.updateAvatar(this.state.avatarList.default.url)}>
                            <td>
                                <img src={this.state.avatarList.default.url} alt={"Avatar " + this.state.avatarList.default.name} width={100}/>
                            </td>
                            <td>
                                {this.state.avatarList.default.name}
                            </td>
                            <td>
                                {this.state.avatarList.default.url}
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <br />
                {this.state.avatarList.categories.map(c => (
                    <>
                        <h5>{c.name}</h5>
                        <Table responsive bordered hover striped>
                            <thead>
                                <tr>
                                    <th>Preview</th>
                                    <th>Name</th>
                                    <th>URL</th>
                                </tr>
                            </thead>
                            <tbody>
                            {c.avatars.map(a => (
                                <tr style={{cursor: "pointer"}} onClick={() => this.updateAvatar(a.url)}>
                                    <td><img src={a.url} width={100} alt={"Avatar " + a.name}/></td>
                                    <td>{a.name}</td>
                                    <td>{a.url}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </>
                ))}
            </>
        )
    }
}


export default withToast(ProfileDetails);