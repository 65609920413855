const ClusterMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Resize Method", id: 'resizeMethod', columnType: 'badge', options: [
                {value: "CONTAIN", style: 'primary'},
                {value: "COVER", style: 'success'}
            ], show: false},
        {label: "Name", id: 'name', columnType: 'default', show: true},
        //{label: "Type", id: 'type', columnType: 'default', show: true},
        {label: "Visibility", id: 'visibility', columnType: 'boolean', show: true},
        {label: "Asset ID", id: 'assetId', columnType: 'default', show: false},
        {label: "Order", id: 'order', columnType: 'default', show: false},

        {label: "Type", id: 'clusterType', columnType: 'customValue', show: true},
        {label: "Icon Preview", id: 'iconPreview', columnType: 'customValue', show: true},
        {label: "Layout Items", id: 'layoutItems', columnType: 'customValue', show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'Name', id: 'name', type: 'text', editable: 'true', required: true}, {label: "Type", id: 'type', type: 'select', options: ["HERO", "TOPIC"], editable: 'true'}],
    ],
    DETAILS_APPEARANCE: [
        [{label: 'Order', id: 'order', type: 'text', editable: 'true'}, {label: 'Resize Method', id: 'resizeMethod', type: 'select', options: ["CONTAIN", "COVER"], editable: 'true'}, {label: 'Visible', id: 'visibility', type: 'boolean', editable: 'true'}],
    ],

    //Sorting
    //CLUSTER_SORT_PARAMS: [{id: 'id', label: 'ID'}], is not supported by this endpoint in spine, TODO: use endpoint in account service?
    CLUSTER_SORT_PARAMS: [],

    NEW_CLUSTER: {id: null, name: null, visibility: true, type: "HERO", assetId: null, order: null, resizeMethod: "CONTAIN", layoutItems: {}},
};

export default ClusterMetaData;