import React, {Component} from "react";
import FormSelect from "react-bootstrap/FormSelect";
import Row from 'react-bootstrap/Row';
import {
    AddCategoriesToProducts,
    AddProductToProductGroup,
    DeleteProduct,
    GetAllProductGroups,
    GetFilteredProducts,
    RemoveProductFromProductGroup,
    UpdateAgeMin,
    UpdateProductState
} from "./ProductService";
import {Button, ButtonGroup, Col, Dropdown, Form, FormControl, InputGroup} from "react-bootstrap";
import ProductMetaData from "./ProductMetaData";
import {withToast} from "../../util/ToastService";
import {CgDetailsMore, FiSettings, GrObjectGroup} from "react-icons/all";
import {ActionModal, TableModal} from "../../generators/ModalGenerator";
import GlobalConstants from "../../config/GlobalConstants";
import {GetFilteredCategories} from "../categories/CategoryService";
import {ResourcesTable, TableHeader, TableSettingsDialog} from "../../generators/TableGenerator";
import {AddProductToSeriesDialog} from "./AddProductToSeriesDialog";
import {
    customDatePickerHeader,
    openExternalTargetInNewTab,
    transformToLocalDate,
    transformToZonedDateTime
} from "../../util/ResourceService";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {ManagementBox, ManagementButton, ManagementSection} from "../../generators/ManagementGenerator";
import {GetFilteredImprints} from "../imprints/ImprintService";
import ReactTooltip from "react-tooltip";
import placeholder from './../../../assets/images/placeholder.png';
import {createLegacyFilterQuery} from "../../clients/RestResourceClient";
import {GetReportingProduct, UpdateReportingProduct} from "../../reporting/reportingProduct/ReportingProductService";
import {GetFilteredAggregators} from "../../reporting/aggregators/AggregatorService";
import {SpinningTiger} from "../../global/SpinningTiger";
import {GetFilteredRecommendationsProducts, UpdateRecommendationProduct} from "../recommendation/RecommendationService";
import RecommendationMetaData from "../recommendation/RecommendationMetaData";
import {ErrorHandler} from "../../util/ErrorHandler";

class Products extends Component {

    constructor(props) {

        super(props);

        this.ref = React.createRef();

        this.state = {
            loading: false,
            error: null,
            preset: "",
            products: [],
            productGroups: [],
            categories: [],
            categoryPage: 1,
            categoryName: null,
            imprintPage: 1,
            imprints: [],
            imprintName: null,
            seriesPage: 1,
            seriesName: null,
            updatedSince: null,
            publicationDate: null,
            filter: props.location.state != null ? props.location.state.filter : {
                page: 0,
                productId: "",
                iapProductIdentifier: "",
                title: "",
                query: "",
                state: ['NEW', 'ACTIVE', 'INACTIVE'],
                ageRangeMin: "",
                ageRangeMax: "",
                lastModifiedDate: "",
                productClassification: ['TIGERBOOK', 'TIGERBOOKDISCOUNT', 'MOVIE', 'EBOOK', 'RADIOPLAY', 'AUDIOBOOK', 'MUSIC', 'SUBSCRIPTION'],
                productType: ['AUDIOBOOK'],
                productGroupId: [],
                categoryCodes: "",
                imprintName: "",
                aggregatorId: "",
                publicationDate: "",
                publicationDateOrder: "AT",
                per_page: 20,
                sortBy: "",
                sortDirection: "",
            },
            totalPages: props.location.state != null ? props.location.state.totalPages : 0,
            series: [],
            selectedSeries: {
                seasons: []
            },
            selectedSeason: {
                episodes: []
            },
            changeProductStateTo: "ACTIVE",
            changeAgeMinTo: "5",
            columns: props.location.state != null ? props.location.state.columns : ProductMetaData.COLUMNS,

            aggregatorPage: 0,
            aggregatorId: null,
            aggregatorName: null,
            aggregators: [],

            customColumnData: [
                {id: 'illustrator', dataset: []},
                {id: 'isbn', dataset: []},
                {id: 'length', dataset: []},
                {id: 'coverUrl', dataset: []},
                {id: 'productGroups', dataset: []},
                {id: 'similarityCategory', dataset: []},
                {id: 'cover', dataset: []},
                {id: 'state', dataset: []}
            ],
            deepLink: "",
            showDialog: {
                tableSettings: false,
                addToProductGroup: false,
                removeFromProductGroup: false,
                productGroupFilter: false,
                categoryFilter: false,
                imprintFilter: false,
                addCategory: false,
                addToSeries: false,
                updateAggregator: false,
                deletionModal: false,
            },
        };

        this.updateIdFilter = this.updateIdFilter.bind(this);
        this.updateIapProductIdentifierFilter = this.updateIapProductIdentifierFilter.bind(this);
        this.updateTitleFilter = this.updateTitleFilter.bind(this);
        this.updateQueryFilter = this.updateQueryFilter.bind(this);
        this.updateStateFilter = this.updateStateFilter.bind(this);
        this.updateTypeFilter = this.updateTypeFilter.bind(this);
        this.updateClassificationFilter = this.updateClassificationFilter.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadPage = this.loadPage.bind(this);
        this.toggleProductGroup = this.toggleProductGroup.bind(this);
        this.updateProductState = this.updateProductState.bind(this);
        this.generateCategoryLink = this.generateCategoryLink.bind(this);
    }

    //INITIAL LOADING
    async componentDidMount() {

        //Set the title
        document.title = "Products :: Tiger UI";

        //Load the product groups
        const loadedProductGroups = await GetAllProductGroups();

        if (loadedProductGroups.error === true) {
            this.setState(prevState => ({...prevState, error: loadedProductGroups}));
        } else {
            loadedProductGroups.result.forEach(productGroup => {
                productGroup.isChecked = false;
            });
        }

        this.setState(prevState => ({
            ...prevState,
            productGroups: loadedProductGroups.result,
            filter: {...prevState.filter,
                sortBy: "id",
                sortDirection: "DESC"
            }
        }));

        //Load the products
        if (this.props.location.state == null) {
            await this.loadPage(this.state.filter.page);
            this.setState(prevState => ({
                ...prevState,
                filter: {...prevState.filter,
                    sortBy: "",
                    sortDirection: ""
                }
            }));
        } else {
            await this.generateDeepLink();
            await this.setCustomData();
        }

    }

    //LOAD DATA
    async loadPage(page) {

        let customColumnData = this.state.customColumnData;
        let placeHolderArray = [];
        for (let i = 0; i < this.state.filter.per_page; i++) {
            placeHolderArray.push(placeholder);
        }
        customColumnData[6].dataset = placeHolderArray;

        this.setState(prevState => ({...prevState, loading: true, customColumnData: customColumnData}));

        let filter = this.state.filter;
        filter.page = page;

        let filteredProducts = [];
        if (filter.publicationDate != null && filter.publicationDate.trim().length > 0) {
            if (filter.publicationDateOrder === "AT") {
                filter.publicationDateBefore = filter.publicationDate;
                filter.publicationDateAfter = filter.publicationDate;
            } else if (filter.publicationDateOrder === "BEFORE") {
                filter.publicationDateBefore = filter.publicationDate;
                filter.publicationDateAfter = null;
            } else if (filter.publicationDateOrder === "AFTER") {
                filter.publicationDateBefore = null;
                filter.publicationDateAfter = filter.publicationDate;
            }
        }
        const response = await GetFilteredProducts(filter);

        if (response.error === true) {
            this.setState(prevState => ({...prevState, error: response}));
        } else {

            filteredProducts = response.result;

            //Activate the checkbox if the product is already checked
            filteredProducts.forEach(product => {
                if (this.state.products.filter(prod => (prod.isChecked === true)).includes(product)) {
                    product.isChecked = true;
                }
            });


        }

        await this.generateDeepLink();

        await this.setState(prevState => ({
            ...prevState,
            loading: false,
            products: filteredProducts,
            page: response.pagination.number,
            totalPages: response.pagination.totalPages,
            customColumnData: customColumnData,
            filter: {...prevState.filter, page: page}
        }));

        await this.setCustomData();
    }

    async loadCategoryPage(page, name) {

        let loadedCategories = {};
        if (name != null) {
            loadedCategories = await GetFilteredCategories({page: page, query: name});
        } else {
            loadedCategories = await GetFilteredCategories({page: page});
        }

        if (loadedCategories.error === true) {
            this.setState(prevState => ({...prevState, error: loadedCategories}));
        }

        this.setState(prevState => ({...prevState, categoryPage: page, categories: loadedCategories.result}));
    }

    async loadImprintPage(page, name) {

        let loadedImprints = {};
        if (name != null) {
            loadedImprints = await GetFilteredImprints({page: page, name: name});
        } else {
            loadedImprints = await GetFilteredImprints({page: page});
        }

        if (loadedImprints.error === true) {
            this.setState(prevState => ({...prevState, error: loadedImprints}));
        }

        this.setState(prevState => ({...prevState, imprintPage: page, imprints: loadedImprints.result}));
    }

    async loadAggregators(page, name) {

        let response = {};
        if (name) {
            response = await GetFilteredAggregators({page: page, name: name});
        } else {
            response = await GetFilteredAggregators({page: page});
        }

        if (response.error === true) {
            this.setState(prevState => ({...prevState, error: response}));
        }

        this.setState(prevState => ({...prevState,
            totalPagesAggregators: response.pagination.totalPages,
            aggregatorPage: response.pagination.number,
            aggregators: response.result}));
    }

    async setCustomData() {

        //Set the covers
        let products = this.state.products;
        for (let product of products) {
            product.coverUrl = product._links && product._links.cover && product._links.cover.href;
            if (!product.coverUrl && product.cover?.contentLocation?.location) {
                product.coverUrl = product.cover.contentLocation.location;
            }
        }

        //Set the custom data
        let customColumnData = this.state.customColumnData;

        customColumnData[0].dataset = products.map(product => product.attributes.illustrator);
        customColumnData[1].dataset = products.map(product => product.attributes.isbn);
        customColumnData[2].dataset = products.map(product => this.getProductLength(product));
        customColumnData[3].dataset = products.map(product => this.transformCoverUrlToLink(product));
        customColumnData[4].dataset = products.map(product => this.generateProductGroupLinks(product));
        customColumnData[5].dataset = products.map(product => this.generateCategoryLink(product));
        customColumnData[6].dataset = products.map(product => product.coverUrl);
        customColumnData[7].dataset = products.map(product => product.state);

        this.setState(prevState => ({...prevState, products: products, customColumnData: customColumnData}));
    }

    handlePageClick = (event) => {
        console.log(
            `User requested page number ${event.selected}`
        );
        this.setState(prevState => ({...prevState, page: event.selected}), () => this.loadPage(event.selected));
    };


    render() {
        return (
            <div style={{marginBottom: 50}}>
                <div className="content-header">
                    <div className="content-header-title">Products</div>
                    <hr/>
                    <div className="content-header-description"><GrObjectGroup/>&#xA0;Manage details, product groups,
                        state and series details of products.
                    </div>
                </div>

                {/* FILTER */}
                <div className="content-box-centered">
                    <div className="content-box-title">Filter</div>
                    <div className="content-box-body">
                        <form onSubmit={this.applyFilter}>
                            <Row>
                                <Form.Group as={Col} className="col-md-3">
                                    <label>Preset</label>
                                    <FormSelect value={this.state.preset}
                                           onChange={(event) => this.changePreset(event.target.value)}>
                                        <option value={""}>No Preset</option>
                                        <option value={"release"}>Release View</option>
                                        <option value={"backlog"}>Backlog View</option>
                                        <option value={"takedown"}>Takedown View</option>
                                    </FormSelect>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} className="col-md-3">
                                    <label>IDs&#xA0;<a style={{color: "black"}} href="#/" data-tip data-for='idTooltip'><CgDetailsMore/></a></label>
                                    <input type="text" value={this.state.filter.productId}
                                           onChange={this.updateIdFilter}/>
                                </Form.Group>
                                <Form.Group as={Col} className="col-md-4">
                                    <label>Title</label>
                                    <input type="text" value={this.state.filter.title}
                                           onChange={this.updateTitleFilter}/>
                                </Form.Group>
                                <Form.Group as={Col} className="col-md-4">
                                    <label>IAP Product Identifier&#xA0;<a style={{color: "black"}} href="#/" data-tip={"iap product id"}
                                                                          data-for='iapProductIdentifierTooltip'><CgDetailsMore/></a></label>
                                    <input type="text" value={this.state.filter.iapProductIdentifier}
                                           onChange={this.updateIapProductIdentifierFilter}/>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} className="col-md-4">
                                    <label>Query</label>
                                    <input type="text" value={this.state.filter.query}
                                           onChange={this.updateQueryFilter}/>
                                </Form.Group>
                                <Form.Group as={Col} className="col-md-1">
                                    <label>Age Min</label>
                                    <input type="number" value={this.state.filter.ageRangeMin}
                                           onChange={(event) => this.setState(prevState => ({
                                               ...prevState,
                                               filter: {...prevState.filter, ageRangeMin: event.target.value}
                                           }))}/>
                                </Form.Group>
                                <Form.Group as={Col} className="col-md-1">
                                    <label>Age Max</label>
                                    <input type="number" value={this.state.filter.ageRangeMax}
                                           onChange={(event) => this.setState(prevState => ({
                                               ...prevState,
                                               filter: {...prevState.filter, ageRangeMax: event.target.value}
                                           }))}/>
                                </Form.Group>
                                <Form.Group as={Col} className="col-md-2">
                                    <label>Publication Date</label><br/>
                                    <DatePicker
                                        todayButton={"Today"}
                                        dateFormat={"yyyy-MM-dd"}
                                        style={{float:"left"}}
                                        renderCustomHeader={({date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled}) => customDatePickerHeader(new Date(date), changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled)}
                                        selected={this.state.publicationDate}
                                        onChange={(date) => {
                                            //Transform the date from datepicker into the correct format
                                            let transformedDate = transformToLocalDate(date.toString());
                                            this.setState(prevState => ({
                                                ...prevState,
                                                publicationDate: date,
                                                filter: {...prevState.filter, publicationDate: transformedDate}
                                            }))
                                        }}/>
                                </Form.Group>
                                <Form.Group className="col-md-1" style={{marginLeft: "-10px"}}>
                                    <label>{this.state.filter.publicationDateOrder === "AT" ? "Exactly At" : (this.state.filter.publicationDateOrder === "BEFORE" ? "Before" : "After")}</label>
                                    <Dropdown as={ButtonGroup} drop="down">
                                        <Button style={{
                                            paddingTop: "4px",
                                            paddingBottom: "4px",
                                            marginBottom: "0",
                                            height: "calc(1.5em + .75rem + 2px)"
                                        }} variant="outline-secondary">
                                            {this.state.filter.publicationDateOrder === "AT" ? "=" : (this.state.filter.publicationDateOrder === "BEFORE" ? "<=" : ">=")}
                                        </Button>
                                        <Dropdown.Toggle split
                                                         variant="outline-secondary"/>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                onClick={() => this.setState(prevState => ({
                                                    ...prevState,
                                                    filter: {
                                                        ...prevState.filter,
                                                        publicationDateOrder: 'AT'
                                                    }
                                                }))}>{"="}</Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => this.setState(prevState => ({
                                                    ...prevState,
                                                    filter: {
                                                        ...prevState.filter,
                                                        publicationDateOrder: 'BEFORE'
                                                    }
                                                }))}>{"<="}</Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => this.setState(prevState => ({
                                                    ...prevState,
                                                    filter: {
                                                        ...prevState.filter,
                                                        publicationDateOrder: 'AFTER'
                                                    }
                                                }))}>{">="}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Form.Group>
                                <Form.Group className="col-md-2">
                                    <label>Updated since</label><br/>
                                    <DatePicker
                                        todayButton={"Today"}
                                        dateFormat={"yyyy-MM-dd"}
                                        renderCustomHeader={({date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled}) => customDatePickerHeader(new Date(date), changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled)}
                                        selected={this.state.updatedSince}
                                        onChange={(date) => {
                                            //Transform the date from datepicker into the correct format
                                            let transformedDate = transformToZonedDateTime(date.toString());
                                            this.setState(prevState => ({
                                                ...prevState,
                                                updatedSince: date,
                                                filter: {...prevState.filter, lastModifiedDate: transformedDate}
                                            }))
                                        }}/>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group className="col-sm-3">
                                    <label>Product Group Ids</label>
                                    <br/>
                                    <InputGroup className={"justify-content-start"}>
                                        <input type="text"  className={"w-70"}
                                               value={this.state.filter.productGroupId.join()}
                                               readOnly={true}/>
                                        <button className="form-btn-ci" type="button"
                                                onClick={() => this.setState({showDialog: {productGroupFilter: true}})}>Add
                                        </button>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group className="col-sm-3">
                                    <label>Category Codes</label>
                                    <br/>
                                    <InputGroup className={"justify-content-start"}>
                                        <input type="text" value={this.state.filter.categoryCodes} className={"w-70"}
                                               onChange={(event) => this.setState(prevState => ({
                                                   ...prevState,
                                                   filter: {...prevState.filter, categoryCodes: event.target.value}
                                               }))}/>
                                        <button className="form-btn-ci" type="button" onClick={() => {
                                            this.loadCategoryPage(1, null).then(r => this.setState({showDialog: {categoryFilter: true}}));

                                        }}>Add
                                        </button>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group className="col-sm-3">
                                    <label>Imprint Name</label>
                                    <br/>
                                    <InputGroup className={"justify-content-start"}>
                                        <input type="text" value={this.state.filter.imprintName} className={"w-70"}
                                               readOnly={true}/>
                                        <button className="form-btn-ci" type="button" onClick={() => {
                                            this.loadImprintPage(1, null).then(r => this.setState({showDialog: {imprintFilter: true}}));
                                        }}>Pick
                                        </button>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group className="col-sm-3">
                                    <label>Aggregator</label>
                                    <br/>
                                    <InputGroup className={"justify-content-start"}>
                                        <input type="text" value={this.state.filter.aggregatorId} className={"w-70"}
                                               readOnly={true}/>
                                        <button className="form-btn-ci" type="button" onClick={() => {
                                            this.loadAggregators(0, null).then(r => this.setState({showDialog: {aggregatorFilter: true}}));
                                        }}>Pick
                                        </button>
                                    </InputGroup>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group className="col-md-3">
                                    <label>State </label>
                                    <br/>
                                    <ButtonGroup className="mb-3">
                                    <Button
                                            variant={this.state.filter.state.indexOf('NEW') > -1 ? "secondary" : "outline-secondary"}
                                            onClick={() => this.updateStateFilter('NEW')}>New</Button>
                                        <Button
                                            variant={this.state.filter.state.indexOf('ACTIVE') > -1 ? "secondary" : "outline-secondary"}
                                            onClick={() => this.updateStateFilter('ACTIVE')}>Active</Button>
                                        <Button
                                            variant={this.state.filter.state.indexOf('INACTIVE') > -1 ? "secondary" : "outline-secondary"}
                                            onClick={() => this.updateStateFilter('INACTIVE')}>Inactive</Button>
                                        <Button
                                            variant={this.state.filter.state.indexOf('TAKEDOWN') > -1 ? "secondary" : "outline-secondary"}
                                            onClick={() => this.updateStateFilter('TAKEDOWN')}>Takedown</Button>
                                    </ButtonGroup>
                                </Form.Group>
                                <Form.Group className="col-md-4">
                                    <label>Type </label>
                                    <br/>
                                    <ButtonGroup className="mb-2">
                                        <Button
                                            variant={this.state.filter.productType.indexOf('TIGERBOOK') > -1 ? "secondary" : "outline-secondary"}
                                            onClick={() => this.updateTypeFilter('TIGERBOOK')}>Tigerbook</Button>
                                        <Button
                                            variant={this.state.filter.productType.indexOf('MOVIE') > -1 ? "secondary" : "outline-secondary"}
                                            onClick={() => this.updateTypeFilter('MOVIE')}>Movie</Button>
                                        <Button
                                            variant={this.state.filter.productType.indexOf('AUDIOBOOK') > -1 ? "secondary" : "outline-secondary"}
                                            onClick={() => this.updateTypeFilter('AUDIOBOOK')}>Audiobook</Button>
                                        <Button
                                            variant={this.state.filter.productType.indexOf('BOOK') > -1 ? "secondary" : "outline-secondary"}
                                            onClick={() => this.updateTypeFilter('BOOK')}>Book</Button>
                                        <Button
                                            variant={this.state.filter.productType.indexOf('SUBSCRIPTION') > -1 ? "secondary" : "outline-secondary"}
                                            onClick={() => this.updateTypeFilter('SUBSCRIPTION')}>Subscription</Button>
                                    </ButtonGroup>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group className="col-md-4">
                                    <label>Classification </label>
                                    <br/>
                                    <ButtonGroup className="mb-4">
                                        <Button
                                            variant={this.state.filter.productClassification.indexOf('TIGERBOOK') > -1 ? "secondary" : "outline-secondary"}
                                            onClick={() => this.updateClassificationFilter('TIGERBOOK')}>Tigerbook</Button>
                                        <Button
                                            variant={this.state.filter.productClassification.indexOf('TIGERBOOKDISCOUNT') > -1 ? "secondary" : "outline-secondary"}
                                            onClick={() => this.updateClassificationFilter('TIGERBOOKDISCOUNT')}>Discount</Button>
                                        <Button
                                            variant={this.state.filter.productClassification.indexOf('MOVIE') > -1 ? "secondary" : "outline-secondary"}
                                            onClick={() => this.updateClassificationFilter('MOVIE')}>Movie</Button>
                                        <Button
                                            variant={this.state.filter.productClassification.indexOf('EBOOK') > -1 ? "secondary" : "outline-secondary"}
                                            onClick={() => this.updateClassificationFilter('EBOOK')}>Ebook</Button>
                                        <Button
                                            variant={this.state.filter.productClassification.indexOf('RADIOPLAY') > -1 ? "secondary" : "outline-secondary"}
                                            onClick={() => this.updateClassificationFilter('RADIOPLAY')}>Radioplay</Button>
                                        <Button
                                            variant={this.state.filter.productClassification.indexOf('AUDIOBOOK') > -1 ? "secondary" : "outline-secondary"}
                                            onClick={() => this.updateClassificationFilter('AUDIOBOOK')}>Audiobook</Button>
                                        <Button
                                            variant={this.state.filter.productClassification.indexOf('MUSIC') > -1 ? "secondary" : "outline-secondary"}
                                            onClick={() => this.updateClassificationFilter('MUSIC')}>Music</Button>
                                        <Button
                                            variant={this.state.filter.productClassification.indexOf('SUBSCRIPTION') > -1 ? "secondary" : "outline-secondary"}
                                            onClick={() => this.updateClassificationFilter('SUBSCRIPTION')}>Subscription</Button>
                                    </ButtonGroup>
                                </Form.Group>
                            </Row>
                            <hr/>
                            <Row>
                                <Form.Group className="col-md-3" style={{marginLeft: "auto"}}>
                                    <button className="form-btn-ci-light" type="button"
                                            onClick={() => this.resetFilter()}>Reset
                                    </button>
                                    <button className="form-btn-ci-blue" type="submit">Apply Filter</button>
                                </Form.Group>
                            </Row>
                        </form>
                    </div>
                </div>

                {/* MANAGEMENT */}
                <ManagementSection content={
                    <>
                        {/* ADD TO */}
                        <ManagementBox width={3} label="Add to" content={[
                            <ManagementButton label="Prod. Groups" enableConditionList={this.state.products}
                                              onClick={() => this.setState({showDialog: {addToProductGroup: true}})}/>,
                            <ManagementButton label={this.state.showDialog.addToSeries ? "Finish" : "Series"}
                                              enableConditionList={this.state.products}
                                              onClick={() => this.setState({showDialog: {addToSeries: !this.state.showDialog.addToSeries}})}/>
                        ]}/>

                        {/* REMOVE FROM */}
                        <ManagementBox width={1} label="Remove from" content={[
                            <ManagementButton label="Prod. Groups" enableConditionList={this.state.products}
                                              onClick={() => this.setState({showDialog: {removeFromProductGroup: true}})}/>
                        ]}/>

                        {/* UPDATE PRODUCTS */}
                        <ManagementBox width={7} label="Update Product" content={[
                            <ManagementButton label="Add Categories" enableConditionList={this.state.products}
                                              onClick={() => {
                                                  this.loadCategoryPage(1, null).then(r => this.setState({showDialog: {addCategory: true}}));
                                              }}/>,
                            <ManagementButton label="Change Aggregator" enableConditionList={this.state.products}
                                              onClick={() => {
                                                  GetFilteredAggregators({}).then(r => this.setState(prevState => ({
                                                      ...prevState,
                                                      aggregators: r.result,
                                                      showDialog: {...prevState.showDialog, updateAggregator: true}
                                                  })));
                                              }}/>,
                            <Dropdown as={ButtonGroup} drop="up">
                                <Button style={{
                                    paddingTop: "8px",
                                    paddingBottom: "10px",
                                    marginBottom: "10px",
                                    height: "40px"
                                }}
                                        variant={this.state.changeProductStateTo === 'NEW' ? "primary" : this.state.changeProductStateTo === 'TAKEDOWN' ? "danger" : this.state.changeProductStateTo === 'INACTIVE' ? "warning" : "success"}
                                        onClick={() => this.updateProductState()}>Change State
                                    To {this.state.changeProductStateTo}</Button>
                                <Dropdown.Toggle style={{marginBottom: "10px"}} split
                                                 variant={this.state.changeProductStateTo === 'NEW' ? "outline-primary" : this.state.changeProductStateTo === 'TAKEDOWN' ? "outline-danger" : this.state.changeProductStateTo === 'INACTIVE' ? "outline-warning" : "outline-success"}/>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => this.setState({changeProductStateTo: 'NEW'})}>New</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => this.setState({changeProductStateTo: 'ACTIVE'})}>Active</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => this.setState({changeProductStateTo: 'INACTIVE'})}>Inactive</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => this.setState({changeProductStateTo: 'TAKEDOWN'})}>Takedown</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>,
                            <InputGroup>
                                <Button style={{
                                    paddingTop: "8px",
                                    paddingBottom: "10px",
                                    marginBottom: "10px",
                                    marginLeft: "10px",
                                    height: "40px"
                                }}
                                        variant="secondary"
                                        onClick={() => this.updateAgeMin()}>Change Age Min
                                    To:</Button>
                                    <input style={{width: "80px", marginBottom: "15px", height: "40px"}} type="number"
                                           value={this.state.changeAgeMinTo}
                                           onChange={(e) => this.setState(prevState => ({
                                               ...prevState,
                                               changeAgeMinTo: e.target.value
                                           }))}/>
                            </InputGroup>
                        ]}/>
                    </>
                }/>

                <div className="content-box-centered">
                    <div className="content-box-title">Deep Link</div>
                    <div className="content-box-body">
                        <Form>
                            <Row>
                                <InputGroup>
                                    <Form.Control type="text" readOnly={true}
                                                  value={this.state.deepLink}

                                    />
                                    <Button variant="outline-secondary"
                                            disabled={this.state.deepLink === ""}
                                            onClick={() => {
                                                navigator.clipboard.writeText(this.state.deepLink).then(r => {
                                                    this.props.addToast("Deep Link copied to clipboard", {
                                                        autoDismiss: true,
                                                        appearance: 'info'
                                                    });
                                                });
                                            }}>Copy to clipboard</Button>
                                </InputGroup>
                            </Row>
                        </Form>
                    </div>
                </div>

                {/* SHOW THE PRODUCT LIST*/}
                <div className="content-box-centered">
                    {!this.state.showDialog.addToSeries &&
                    <>
                        <div className="content-box-title">Product List
                            <div style={{float: "right", fontWeight: "normal", cursor: "pointer"}}
                                 onClick={() => this.setState(prevState => ({
                                     ...prevState,
                                     showDialog: {...prevState.showDialog, tableSettings: true}
                                 }))}><FiSettings/></div>
                        </div>
                        <div ref={this.ref} className="content-box-body">
                            <TableHeader nameInState="products"
                                         sortParams={ProductMetaData.PRODUCT_SORT_PARAMS}
                                         defaultSortParamLabel={"Relevanz"}
                                         state={this.state}
                                         onSetState={(s) => this.setState(s)}
                                         onLoadPage={() => this.loadPage(this.state.filter.page)}/>
                            <hr/>
                            {this.state.loading ?
                                <SpinningTiger />
                            :
                                <ResourcesTable
                                    state={this.state}
                                    columns={this.state.columns}
                                    customcolumndata={this.state.customColumnData}
                                    dataset={this.state.products}
                                    page={this.state.filter.page}
                                    totalPages={this.state.totalPages}
                                    handlePageClick={(event) => this.loadPage(event.selected)}
                                    onLoadPage={(page) => this.loadPage(page)}
                                    selectable={true}
                                    resourcesurl='products'
                                    nameInState='products'
                                    isSpringDataRestResource={true}
                                    onToggleResource={(allProducts) => this.setState(prevState => ({
                                        ...prevState,
                                        products: allProducts
                                    }))}/>
                            }
                        </div>
                    </>
                    }

                    {/* SHOW THE SERIES LIST*/}
                    {this.state.showDialog.addToSeries &&
                    <>
                        <div className="content-box-title">Add the selected products to a series</div>
                        <div className="content-box-body">
                            <AddProductToSeriesDialog
                                products={this.state.products.filter(product => product.isChecked)}
                                series={{seasons: []}}/>
                        </div>
                    </>
                    }
                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showDialog.tableSettings}
                                     columns={this.state.columns}
                                     onSetState={(columns) => this.setState(prevState => ({
                                         ...prevState,
                                         columns: columns
                                     }))}
                                     onHide={() => this.setState({showDialog: {tableSettings: false}})}
                />

                {/* DIALOGS */}
                <TableModal show={this.state.showDialog.productGroupFilter}
                            onHide={() => this.setState({showDialog: {productGroupFilter: false}})}
                            title="Click on a product group to add it"
                            tableHeader={[{label: "ID", id: "id"}, {label: "Name", id: "name"}]}
                            tableData={this.state.productGroups}
                            onToggle={(productGroup) => this.updateProductGroupFilter(productGroup)}
                            closeButtonText="Apply"
                            selectable={false}/>

                <TableModal show={this.state.showDialog.categoryFilter}
                            onHide={() => this.setState({showDialog: {categoryFilter: false}})}
                            title="Click on a category to add it"
                            tableHeader={[{label: "ID", id: "id"}, {label: "Name", id: "name"}, {
                                label: "Code",
                                id: "code"
                            }]}
                            tableData={this.state.categories}
                            onToggle={(category) => {
                                this.updateCategoryFilter(category);
                            }}
                            selectable={false}
                            page={this.state.categoryPage}
                            onLoadPage={(page) => this.loadCategoryPage(page, this.state.categoryName)}
                            closeButtonText="Apply"
                            filter={this.categoryFilter()}/>

                <TableModal show={this.state.showDialog.updateAggregator}
                            onHide={() => this.setState(prevState => ({
                                ...prevState,
                                showDialog: {...prevState.showDialog, updateAggregator: false}
                            }))}
                            title="Update the aggregator to"
                            tableHeader={[{label: "ID", id: 'id'}, {
                                label: "Name",
                                id: 'name'
                            }, {label: "Financial Share", id: 'financialShare'}]}
                            tableData={this.state.aggregators}
                            onToggle={(agg) => this.updateAggregator(agg)}
                            selectable={false}
                />

                <TableModal show={this.state.showDialog.imprintFilter}
                            onHide={() => this.setState({showDialog: {imprintFilter: false}})}
                            title="Click on a imprint to select it"
                            tableHeader={[{label: "ID", id: "id"}, {label: "Name", id: "name"}, {
                                label: "Publishing House ID",
                                id: "publishingHouseId"
                            }, {label: "Publishing House Name", id: "publishingHouseName"}]}
                            tableData={this.state.imprints}
                            onToggle={(imprint) => this.setState(prevState => ({
                                ...prevState,
                                filter: {...prevState.filter, imprintName: imprint.name}
                            }))}
                            selectable={false}
                            page={this.state.imprintPage}
                            onLoadPage={(page) => this.loadImprintPage(page, this.state.imprintName)}
                            closeButtonText="Apply"
                            filter={this.imprintFilter()}/>

                <TableModal show={this.state.showDialog.aggregatorFilter}
                            onHide={() => this.setState({showDialog: {aggregatorFilter: false}})}
                            title="Click on a aggregator to select it"
                            tableHeader={[{label: "ID", id: "id"}, {label: "Name", id: "name"}]}
                            tableData={this.state.aggregators}
                            keyName={"id"}
                            onToggle={(aggregator) => this.setState(prevState => ({
                                ...prevState,
                                filter: {...prevState.filter, aggregatorId: aggregator.id}
                            }))}
                            selectable={false}
                            page={this.state.aggregatorPage}
                            onLoadPage={(page) => this.loadAggregators(page, this.state.aggregatorName)}

                            handlePageClick={(event) => this.loadAggregators(event.selected)}
                            totalPages={this.state.totalPagesAggregators}

                            closeButtonText="Apply"
                            filter={this.aggregatorFilter()}/>

                <TableModal
                    show={this.state.showDialog.addToProductGroup || this.state.showDialog.removeFromProductGroup}
                    onHide={() => this.setState({
                        showDialog: {
                            addToProductGroup: false,
                            removeFromProductGroup: false
                        }
                    })}
                    onAction={() => this.addOrRemoveProductGroup()}
                    title={this.state.showDialog.addToProductGroup ? "Add selected products to product group" : "Remove selected products from product group"}
                    tableHeader={[{label: "ID", id: "id"}, {label: "Name", id: "name"}]}
                    tableData={this.state.productGroups}
                    onToggle={(productGroup) => this.toggleProductGroup(productGroup)}
                    selectable={true}/>

                <TableModal show={this.state.showDialog.addCategory}
                            onHide={() => this.setState({showDialog: {addCategory: false}})}
                            onAction={() => this.addCategories()}
                            title="Add categories to the selected products"
                            tableHeader={[{label: "ID", id: "id"}, {label: "Name", id: "name"}, {
                                label: "Code",
                                id: "code"
                            }]}
                            tableData={this.state.categories}
                            onToggle={(category) => this.toggleCategory(category)}
                            selectable={true}
                            page={this.state.categoryPage}
                            onLoadPage={(page) => this.loadCategoryPage(page, this.state.categoryName)}
                            filter={this.categoryFilter()}/>

                <ActionModal show={this.state.showDialog.deletionModal}
                             onHide={() => this.setState((prevState) => ({
                                 ...prevState,
                                 showDialog: {...prevState.showDialog, deletionModal: false}
                             }))}
                             onAction={() => this.deleteSelectedProducts()}
                             actionButtonText="Delete"
                             title={"Delete products"}
                             body={"Are you sure you want to delete the selected products?"}/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))}
                />

                {/* TOOLTIPS */}
                <ReactTooltip id='iapProductIdentifierTooltip' aria-haspopup='true' role='example'>
                    <p style={{fontWeight: "bold"}}>ISBN Filter</p>
                    <p>Filter for products with certain ISBN numbers. Multiple entries allowed.</p>
                    <p style={{fontWeight: "bold"}}>Example: <span
                        style={{fontStyle: "italic"}}>9783864560156,9783862745913</span></p>
                </ReactTooltip>
                <ReactTooltip id='idTooltip' aria-haspopup='true' role='example'>
                    <p style={{fontWeight: "bold"}}>ID Filter</p>
                    <p>Filter for products with certain IDs. Multiple entries allowed.</p>
                    <p style={{fontWeight: "bold"}}>Example: <span style={{fontStyle: "italic"}}>2030006,2030017</span>
                    </p>
                </ReactTooltip>

            </div>
        );
    }

    //ACTIONS
    async addOrRemoveProductGroup() {

        this.setState({showDialog: {addToProductGroup: false, removeFromProductGroup: false}})
        this.props.addToast("Request to add/remove products from product groups has been started. This may take a moment.", {
            autoDismiss: true,
            appearance: "info"
        });

        const selectedProducts = this.state.products.filter(product => product.isChecked === true);
        const selectedProductGroups = this.state.productGroups.filter(productGroup => productGroup.isChecked === true);

        let i = 0;
        let errors = 0;

        //Perform the update
        for (const productGroup of selectedProductGroups) {
            for (const product of selectedProducts) {
                let result = {};
                if (this.state.showDialog.addToProductGroup) {
                    result = await AddProductToProductGroup(product, productGroup);
                } else {
                    result = await RemoveProductFromProductGroup(product, productGroup);
                }

                if (result.error) {
                    errors++;
                    this.props.addToast("Product " + product.id + " can not be added/removed to/from product group " + productGroup.id + ". Cause: " + result.message, {
                        autoDismiss: true,
                        appearance: 'error'
                    });
                }
            }

            i++;

            //We only have to reload the products if at least one product has been added/removed from an group
            if (i >= selectedProductGroups.length && errors < (selectedProducts.length * selectedProductGroups.length)) {

                if (this.state.showDialog.addToProductGroup) {
                    this.props.addToast("The products have been added successfully.", {
                        autoDismiss: true,
                        appearance: 'success'
                    });
                } else {
                    this.props.addToast("The products have been removed successfully.", {
                        autoDismiss: true,
                        appearance: 'success'
                    });
                }

                this.state.productGroups.forEach(productGroup => {
                    productGroup.isChecked = false;
                });

                await this.loadPage(this.state.filter.page);
            }
        }
    }

    async addCategories() {

        const selectedProducts = this.state.products.filter(product => product.isChecked === true);
        const selectedCategories = this.state.categories.filter(category => category.isChecked === true);

        await AddCategoriesToProducts(selectedProducts, selectedCategories);

        this.props.addToast("The categories have been added successfully to the products.", {
            autoDismiss: true,
            appearance: 'success'
        });

        this.state.categories.forEach(category => {
            category.isChecked = false;
        });

        this.setState({showDialog: {addCategory: false}})

    }

    async updateAgeMin() {
        const selectedProducts = this.state.products.filter(product => product.isChecked === true);

        this.props.addToast("Request to change the min age has been started. This may take a moment.", {
            autoDismiss: true,
            appearance: "info"
        });

        let i = 0;
        let errors = 0;

        //Perform the update
        for (const product of selectedProducts) {
            let result = await UpdateAgeMin(product, this.state.changeAgeMinTo);

            if (result.error) {
                errors++;
                this.props.addToast("Age Min of product " + product.id + " can not be changed. Cause: " + result.message, {
                    autoDismiss: true,
                    appearance: 'error'
                });
            }

            i++;

            //We only have to reload the products if at least one product state has been changed
            if (i >= selectedProducts.length && errors < selectedProducts.length) {
                await this.loadPage(this.state.filter.page);
            }
        }
    }

    async updateAggregator(aggregator) {
        const selectedProducts = this.state.products.filter(product => product.isChecked === true);

        this.props.addToast("Request to change aggregators of selected products has been started. This may take a moment.", {
            autoDismiss: true,
            appearance: "info"
        });

        let i = 0;
        let errors = 0;

        for (const product of selectedProducts) {

            //Get the reporting product if there is any
            let loadedRepoPro = await GetReportingProduct(product.id);
            if (!loadedRepoPro.error) {
                let repoPro = loadedRepoPro.result;
                repoPro.aggregator = aggregator;
                let updatedRepoPro = await UpdateReportingProduct(product.id, repoPro);
                if (updatedRepoPro.error) {
                    errors++;
                    this.props.addToast("Updating the reporting product of product " + product.id + " failed. Cause: " + updatedRepoPro.message + ".", {
                        appearance: "error",
                        autoDismiss: true
                    });
                }
            } else {
                errors++;
                this.props.addToast("Updating the reporting product of product " + product.id + " failed. Cause: " + loadedRepoPro.message + ".", {
                    appearance: "error",
                    autoDismiss: true
                });
            }

            i++;

            //We only have to reload the products if at least one product state has been changed
            if (i >= selectedProducts.length && errors < selectedProducts.length) {
                await this.loadPage(this.state.filter.page);
            }
        }
    }

    async updateProductState() {

        const selectedProducts = this.state.products.filter(product => product.isChecked === true);

        this.props.addToast("Request to update the product state has been started. This may take a moment.", {
            autoDismiss: true,
            appearance: "info"
        });

        if (this.state.changeProductStateTo === "") {
            this.props.addToast("Please select a product state the products should be changed to.", {
                autoDismiss: true,
                appearance: 'warning'
            });
        } else {

            let i = 0;
            let errors = 0;

            //Perform the update
            for (const product of selectedProducts) {
                let result = await UpdateProductState(product, this.state.changeProductStateTo);

                if (result.error) {
                    errors++;
                    this.props.addToast("State of product " + product.id + " can not be changed. Cause: " + result.message, {
                        autoDismiss: true,
                        appearance: 'error'
                    });
                } else {
                    //Update the recommendation product if there is any
                    let recommendationRes = await GetFilteredRecommendationsProducts({productId: product.id});
                    if(!recommendationRes.error && recommendationRes.result.length > 0){
                        let newProductRecommendationList = recommendationRes.result.filter(p => p.notificationType === RecommendationMetaData.RECOMMENDATION_PRODUCT_TYPES[0].id);
                        if(newProductRecommendationList.length > 0) {
                            let newProductRecommendation = newProductRecommendationList[0];
                            newProductRecommendation.state = this.state.changeProductStateTo;
                            let response = await UpdateRecommendationProduct(newProductRecommendation);
                            if(response.error) {
                                this.props.addToast("Product state of recommendation " + newProductRecommendation.id + " can not be updated. Cause: " + response.message, {
                                    autoDismiss: true,
                                    appearance: "error"
                                });
                            }
                        }
                    }
                }

                i++;

                //We only have to reload the products if at least one product state has been changed
                if (i >= selectedProducts.length && errors < selectedProducts.length) {
                    await this.loadPage(this.state.filter.page);
                }
            }
        }
    }

    async deleteSelectedProducts() {
        this.state.products
            .filter(product => product.isChecked === true)
            .forEach(product => DeleteProduct(product));

        await this.loadPage(this.state.filter.page);

        this.setState(prevState => ({...prevState, showDialog: {...prevState.showDialog, deletionModal: false}}));
    }

    async generateDeepLink() {
        //First the product type Query must be transformed from ',AUDIOBOOK,BOOK,' to 'AUDIOBOOK'
        let typeString = this.state.filter.productType;

        if (typeString !== "") {
            let index = 0;

            //Remove the leading "," if there is one (i.e. ',AUDIOBOOK,BOOK' -> 'AUDIOBOOK,BOOK')
            if (typeString[0] === ",") {
                typeString = typeString.slice(typeString[1], typeString.length);
            }

            //Now get the index of 'real' first "," (if there is any, i.e. 'AUDIOBOOK,BOOK' -> 9) and slice the string (i.e. 'AUDIOBOOK,BOOK' -> 'AUDIOBOOK')
            index = this.state.filter.productType.indexOf(",");
            if (index > -1) {
                typeString = typeString.slice(0, index);
            }
        }
        let filter = {
            query: this.state.filter.query !== "" ? this.state.filter.query : null,
            ageRangeMin: this.state.filter.ageRangeMin !== "" ? this.state.filter.ageRangeMin : null,
            ageRangeMax: this.state.filter.ageRangeMax !== "" ? this.state.filter.ageRangeMax : null,
            imprintName: this.state.filter.imprintName !== "" ? this.state.filter.imprintName : null,
            aggregatorId: this.state.filter.aggregatorId !== "" ? this.state.filter.aggregatorId : null,
            productType: typeString !== "" ? typeString[0] : null,
            productGroupId: this.state.filter.productGroupId.length > 0 ? this.state.filter.productGroupId[0] : null,
            categoryCodes: this.state.filter.categoryCodes.length > 0 ? this.state.filter.categoryCodes[0] : null,
            sortBy: this.state.filter.sortBy !== "" ? this.state.filter.sortBy : null,
            sortDirection: this.state.filter.sortDirection !== "" ? this.state.filter.sortDirection : null,
        }

        let generatedDeepLink = GlobalConstants.SEARCH_CLIENT_HOST + 'api/searches/products-by-clients' + createLegacyFilterQuery(filter);

        this.setState(prevState => ({...prevState, deepLink: generatedDeepLink}));
    }

    //FILTER
    updateTitleFilter(event) {
        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                title: event.target.value,
            },
        }));
    }

    updateIapProductIdentifierFilter(event) {
        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                iapProductIdentifier: event.target.value
            }
        }))
    }

    updateQueryFilter(event) {
        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                query: event.target.value,
            },
        }));
    }

    updateIdFilter(event) {
        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                productId: event.target.value,
            },
        }));
    }

    updateStateFilter(state) {
        let states = this.state.filter.state;

        //Check if the selected state is already in the filter
        const index = states.indexOf(state);
        if (index > -1) {
            states.splice(index, 1);
        } else {
            states.push(state);
        }
        this.setState(prevState => ({...prevState, filter: {...prevState.filter, state: states}}));
    }

    updateTypeFilter(type) {
        let types = this.state.filter.productType;

        //Check if the selected state is already in the filter
        const index = types.indexOf(type);
        if (index > -1) {
            types.splice(index, 1);
        } else {
            types.push(type);
        }
        this.setState(prevState => ({...prevState, filter: {...prevState.filter, productType: types}}));
    }

    updateClassificationFilter(classification) {
        let classifications = this.state.filter.productClassification;

        //Check if the selected state is already in the filter
        const index = classifications.indexOf(classification);
        if (index > -1) {
            classifications.splice(index, 1);
        } else {
            classifications.push(classification);
        }
        this.setState(prevState => ({
            ...prevState,
            filter: {...prevState.filter, productClassification: classifications}
        }));
    }

    updateProductGroupFilter(productGroup) {
        let ids = [];

        if (this.state.filter.productGroupId.includes(productGroup.id)) {
            ids = this.state.filter.productGroupId.filter(id => id !== productGroup.id);
        } else {
            ids = this.state.filter.productGroupId.concat(productGroup.id);
        }

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                productGroupId: ids,
            }
        }));
    }

    updateCategoryFilter(category) {
        let codes = "";

        if (this.state.filter.categoryCodes === "") {
            codes = category.code;
        } else {
            if (!this.state.filter.categoryCodes.includes(category.code)) {
                codes = this.state.filter.categoryCodes.concat("," + category.code);
            }
        }

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                categoryCodes: codes + "",
            }
        }));
    }

    categoryFilter() {
        return (
            <>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Search for a category by name"
                        onChange={(e) => this.setState(prevState => ({
                            ...prevState,
                            categoryName: e.target.value
                        }))}
                    />
                        <Button variant="outline-secondary"
                                onClick={() => this.loadCategoryPage(this.state.categoryPage, this.state.categoryName)}>Search</Button>
                </InputGroup>
            </>);
    }

    imprintFilter() {
        return (
            <>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Search for an imprint by name"
                        onChange={(e) => this.setState(prevState => ({
                            ...prevState,
                            imprintName: e.target.value
                        }))}
                    />
                        <Button variant="outline-secondary"
                                onClick={() => this.loadImprintPage(this.state.imprintPage, this.state.imprintName)}>Search</Button>
                </InputGroup>
            </>);
    }

    aggregatorFilter() {
        return (
                <>
                    <InputGroup className="mb-3">
                        <FormControl
                                placeholder="Search for an aggregator by name"
                                onChange={(e) => this.setState(prevState => ({
                                    ...prevState,
                                    aggregatorName: e.target.value
                                }))}
                        />
                            <Button variant="outline-secondary"
                                    onClick={() => this.loadAggregators(this.state.aggregatorPage, this.state.aggregatorName)}>Search</Button>
                    </InputGroup>
                </>);
    }

    async applyFilter(event) {

        if (event) {
            event.preventDefault();
        }

        let filteredProducts = [];

        let filter = this.state.filter;

        if (filter.publicationDate != null && filter.publicationDate.trim().length > 0) {
            if (filter.publicationDateOrder === "AT") {
                filter.publicationDateBefore = filter.publicationDate;
                filter.publicationDateAfter = filter.publicationDate;
            } else if (filter.publicationDateOrder === "BEFORE") {
                filter.publicationDateBefore = filter.publicationDate;
                filter.publicationDateAfter = null;
            } else if (filter.publicationDateOrder === "AFTER") {
                filter.publicationDateBefore = null;
                filter.publicationDateAfter = filter.publicationDate;
            }
        }

        this.setState(prevState => ({...prevState, products: [], loading: true}));
        const response = await GetFilteredProducts({...filter, page: 0});

        if (response.error === true) {
            this.setState(prevState => ({...prevState, error: response}));
        } else {

            filteredProducts = response.result;

            if (filteredProducts.length === 0) {
                this.props.addToast("No products match the filter criteria", {
                    autoDismiss: true,
                    appearance: 'warning'
                });
            } else {
                this.ref.current.scrollIntoView({behavior: "smooth"});
            }
        }

        this.setState(prevState => ({...prevState,
            products: filteredProducts,
            page: response.pagination.number,
            totalPages: response.pagination.totalPages,
            loading: false}));

        await this.generateDeepLink();

        await this.setCustomData();
    }

    resetFilter() {
        this.setState(prevState => ({
            ...prevState,
            updatedSince: null,
            publicationDate: null,
            filter: {
                page: 0,
                productId: "",
                iapProductIdentifier: "",
                title: "",
                query: "",
                ageRangeMin: "",
                ageRangeMax: "",
                lastModifiedDate: "",
                productClassification: ['TIGERBOOK', 'TIGERBOOKDISCOUNT', 'MOVIE', 'EBOOK', 'RADIOPLAY', 'AUDIOBOOK', 'MUSIC'],
                productType: ['AUDIOBOOK'],
                state: ["NEW", "ACTIVE", "INACTIVE", "TAKEDOWN"],
                publicationDate: "",
                publicationDateOrder: "AT",
                productGroupId: [],
                categoryCodes: "",
                imprintName: "",
                aggregatorId: "",
                per_page: 20,
                sortBy: "",
                sortDirection: "",
            }
        }));
    }

    //SELECTION
    toggleProductGroup(productGroup) {
        let productGroups = this.state.productGroups;
        const index = productGroups.indexOf(productGroup);
        let group = productGroups[index];
        group.isChecked = !group.isChecked;
        productGroups[index] = group;
        this.setState(prevState => ({...prevState, productGroups: productGroups}));
    }

    toggleCategory(category) {
        let categories = this.state.categories;
        const index = categories.indexOf(category);
        let cat = categories[index];
        cat.isChecked = !cat.isChecked;
        categories[index] = cat;
        this.setState(prevState => ({...prevState, categories: categories}));
    }

    //HELPER
    getProductLength(product) {
        if (product.productType === "BOOK" || product.productType === "TIGERBOOK") {
            return product.attributes.totalPages;
        } else if (product.productType === "MOVIE" || product.productType === "AUDIOBOOK") {
            if (product.attributes.length != null) {
                const seconds = product.attributes.length
                const hours = Math.floor(seconds / 3600);
                const minutes = Math.floor((seconds % 3600) / 60);
                const remainingSeconds = seconds % 60;

                let result = "";
                if (hours > 0) {
                    result += hours + ":";
                }
                if (minutes > 0) {
                    result += (minutes < 10 ? "0" + minutes : minutes) + ":";
                }
                result += (remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds);

                return result;
            } else {
                return null;
            }
        } else if (product.productType === "SUBSCRIPTION") {
            return product.attributes.duration;
        } else {
            return null;
        }
    }

    generateProductGroupLinks(product) {
        let ids = "";
        if (product.productGroupIds) {
            ids = product.productGroupIds.map(groupId => (
                    <>
                        <ul style={{listStyleType: "square"}}>
                            <li><a style={{color: "#333", fontStyle: "italic"}}
                                   href={GlobalConstants.SPINE_CLIENT_HOST + GlobalConstants.APP_PATH_PRODUCTGROUP_DETAILS + groupId}>{groupId}</a> - {this.getProductGroupName(groupId)}
                            </li>
                        </ul>
                    </>
            ));
        }

        return ids;
    }

    getProductGroupName(groupId) {
        let name = "";
        let matchingGroups = this.state.productGroups.filter(group => group.id === groupId);
        if (matchingGroups.length > 0) {
            name = matchingGroups[0].name;
        }
        return name;
    }



    generateCategoryLink(product) {
        let link = null;

        if (product.similarityCategory) {
            link = (
                <>
                    <a style={{color: "#333", fontStyle: "italic"}}
                       href={GlobalConstants.SPINE_CLIENT_HOST + GlobalConstants.APP_PATH_CATEGORY_DETAILS + product.similarityCategory.id}>{product.similarityCategory.id}</a> - {product.similarityCategory.name}
                </>
            );
        }
        return link;
    }

    transformCoverUrlToLink(product) {
        if (product.coverUrl != null) {
            return (
                <>
                    <Button variant="outline-secondary"
                            onClick={() => openExternalTargetInNewTab(product.coverUrl)}>Open</Button>
                    &#xA0;
                    &#xA0;
                    <Button variant="outline-secondary"
                            onClick={() => {
                                navigator.clipboard.writeText(product.coverUrl).then(r => {
                                    this.props.addToast("Cover URL copied to clipboard", {
                                        autoDismiss: true,
                                        appearance: 'info'
                                    });
                                });
                            }}>Copy</Button>
                </>
            )
        } else {
            return null;
        }
    }

    changePreset(preset) {
        this.resetFilter();

        let idColumn;
        let stateColumn;
        let productTypeColumn;
        let titleColumn;
        let popularityColumn;
        let publicationDateColumn;
        let automaticReleaseColumn;
        let ageMinColumn;
        let lengthColumn;
        let coverColumn;
        this.state.columns.forEach(column => {
            column.show = false;
            if (column.id === 'id') {
                idColumn = column;
            }
            if (column.id === 'state') {
                stateColumn = column;
            }
            if (column.id === 'productType') {
                productTypeColumn = column;
            }
            if (column.id === 'title') {
                titleColumn = column;
            }
            if (column.id === 'popularity') {
                popularityColumn = column;
            }
            if (column.id === 'publicationDate') {
                publicationDateColumn = column;
            }
            if (column.id === 'automaticRelease') {
                automaticReleaseColumn = column;
            }
            if (column.id === 'ageMin') {
                ageMinColumn = column;
            }
            if (column.id === 'length') {
                lengthColumn = column;
            }
            if (column.id === 'cover') {
                coverColumn = column;
            }
        });

        if (preset && preset !== '') {
            console.log("selected preset " + preset);

            if (preset === 'release')
            {
                idColumn.show = true;
                stateColumn.show = true;
                titleColumn.show = true;
                popularityColumn.show = true;
                publicationDateColumn.show = true;
                automaticReleaseColumn.show = true;
                ageMinColumn.show = true;
                lengthColumn.show = true;
                coverColumn.show = true;
                let date = new Date();
                let transformedDate = transformToLocalDate(date.toString());

                this.setState(prevState => ({
                    ...prevState,
                    updatedSince: null,
                    publicationDate: date,
                    preset: preset,
                    filter: {
                        page: 0,
                        productId: "",
                        iapProductIdentifier: "",
                        title: "",
                        query: "",
                        ageRangeMin: "",
                        ageRangeMax: "",
                        lastModifiedDate: "",
                        productClassification: ['RADIOPLAY', 'AUDIOBOOK', 'MUSIC'],
                        productType: ['AUDIOBOOK'],
                        state: ["NEW", "INACTIVE"],
                        publicationDate: transformedDate,
                        publicationDateOrder: "AFTER",
                        productGroupId: [474920005],
                        categoryCodes: "",
                        imprintName: "",
                        aggregatorId: "",
                        per_page: 200,
                        sortBy: "publicationDate",
                        sortDirection: "ASC",
                    }
                }), () => this.loadPage(0));
            } else if (preset === 'backlog') {
                idColumn.show = true;
                stateColumn.show = true;
                titleColumn.show = true;
                popularityColumn.show = true;
                publicationDateColumn.show = true;
                ageMinColumn.show = true;
                lengthColumn.show = true;
                coverColumn.show = true;
                let date = new Date();
                let transformedDate = transformToLocalDate(date.toString());

                this.setState(prevState => ({
                        ...prevState,
                        updatedSince: null,
                        publicationDate: date,
                    preset: preset,
                        filter: {
                            page: 0,
                            productId: "",
                            iapProductIdentifier: "",
                            title: "",
                            query: "",
                            ageRangeMin: "",
                            ageRangeMax: "",
                            lastModifiedDate: "",
                            productClassification: ['RADIOPLAY', 'AUDIOBOOK', 'MUSIC'],
                            productType: ['AUDIOBOOK'],
                            state: ["NEW", "INACTIVE"],
                            publicationDate: transformedDate,
                            publicationDateOrder: "BEFORE",
                            productGroupId: [474920005],
                            categoryCodes: ['NOT cat.subject.englisch','NOT cat.series.hoerprobe','NOT cat.language.russisch','NOT cat.language.franzoesisch','NOT cat.language.finnisch','NOT cat.language.italienisch','NOT cat.language.spanisch','NOT cat.backlog.ab14','NOT cat.backlog.religion','NOT cat.backlog.dublette','NOT cat.backlog.defekt','NOT cat.backlog.trashbooks','NOT cat.backlog.trashmusic','NOT cat.backlog.adult','NOT cat.backlog.system','NOT cat.backlog.nochnichtstreaming'],
                            imprintName: "",
                            aggregatorId: "",
                            per_page: 200,
                            sortBy: "publicationDate",
                            sortDirection: "DESC",
                        }
                    }), () => this.loadPage(0));
            } else if (preset === 'takedown') {
                idColumn.show = true;
                stateColumn.show = true;
                titleColumn.show = true;
                popularityColumn.show = true;
                publicationDateColumn.show = true;
                ageMinColumn.show = true;
                lengthColumn.show = true;
                coverColumn.show = true;

                this.setState(prevState => ({
                    ...prevState,
                    updatedSince: null,
                    publicationDate: null,
                    preset: preset,
                    filter: {
                        page: 0,
                        productId: "",
                        iapProductIdentifier: "",
                        title: "",
                        query: "",
                        ageRangeMin: "",
                        ageRangeMax: "",
                        lastModifiedDate: "",
                        productClassification: ['RADIOPLAY', 'AUDIOBOOK', 'MUSIC'],
                        productType: ['AUDIOBOOK'],
                        state: ["TAKEDOWN"],
                        publicationDate: null,
                        publicationDateOrder: "AT",
                        productGroupId:  [],
                        categoryCodes: "",
                        imprintName: "",
                        aggregatorId: "",
                        per_page: 200,
                        sortBy: "takedownDate",
                        sortDirection: "DESC",
                    }
                }), () => this.loadPage(0));
            }
        } else {
            idColumn.show = true;
            stateColumn.show = true;
            titleColumn.show = true;
            productTypeColumn.show = true;
            coverColumn.show = true;

            this.setState(prevState => ({
                ...prevState,
                columns: ProductMetaData.COLUMNS,
                preset: '',
            }), () => this.loadPage(0));
        }
    }

}

export default withToast(Products);