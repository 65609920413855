import {Badge, Button, Col, Form, InputGroup, Table} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {
    AiOutlineMail,
    BiSitemap,
    GrTechnology, IoPeopleCircleOutline, RiLockPasswordLine, VscListUnordered,
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {
    ChangeEmail,
    ChangePassword,
    ChangeRoles,
    CreateAccount, DeleteAccount,
    GetAccount, GetAccountRoles,
    GetAllRoles, GetRecentlyUsedProducts,
    UpdateAccount
} from "./AccountService";
import {DetailsSection, RequiredFieldsAreValid} from "../../generators/DetailsGenerator";
import AccountMetaData from "./AccountMetaData";
import {Label} from "reactstrap";
import {ActionModal, InfoModal} from "../../generators/ModalGenerator";
import {GetFilteredRestores} from "../restore/RestoreService";
import {AccountRestores} from "./AccountRestores";
import {GetFilteredOrders} from "../order/OrderService";
import {AccountOrders} from "./AccountOrders";
import {GetFilteredSubscriptions} from "../subscriptions/SubscriptionService";
import {AccountSubscriptions} from "./AccountSubscriptions";
import {GetFilteredAcquisitions} from "../acquisitions/AcquisitionService";
import {AccountAcquisitions} from "./AccountAcquisitions";
import {AccountProfiles} from "./AccountProfiles";
import {GetFilteredProfiles} from "../profiles/ProfileService";
import {DTTSingleGeneratorDialog} from "../../tools/support/dialogs/DTTSingleGeneratorDialog";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class AccountDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            loading: false,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true
            },
            account: {
                accountType: 'DEFAULT',
                enabled: true,
                roles: [],
                recentlyUsedProducts: []
            },
            originalAccount: {},
            recentlyUsedProducts: [],
            availableRoles: [],
            sections: {
                general: true,
                customerDetails: true,
                relationshipDetails: true,
                technicalDetails: true,
                recentlyUsedProducts: false,
                roles: true
            },
            validationErrors: [],
            showRolesDialog: false,
            showEmailDialog: false,
            showPasswordDialog: false,
            updatePasswordRequest: "",
            updatePasswordConfirmRequest: "",
            //Restores
            accountRestores: [],
            restorePage: 1,
            lastRestorePage: 1,
            showRestores: false,
            //Orders
            accountOrders: [],
            orderPage: 1,
            lastOrderPage: 1,
            showOrders: false,
            //Subscriptions
            accountSubscriptions: [],
            subscriptionPage: 1,
            lastSubscriptionPage: 1,
            showSubscriptions: false,
            //Acquisitions
            accountAcquisitions: [],
            acquisitionPage: 1,
            lastAcquisitionPage: 1,
            showAcquisitions: false,
            //Profiles
            accountProfiles: [],
            userPage: 1,
            lastUserPage: 1,
            showProfiles: false,

            //Tools
            showTicketGeneratorDialog: false,
            showDeletionDialog: false,
            confirmDeletion: false
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if (this.state.id != null) {
            document.title = "Account " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New account :: Tiger UI";
        }

        await this.loadAccount();
        await this.loadRecentlyUsedProducts();
    }

    //--------
    //Loading
    //--------

    async loadAccount() {
        let loadedAccount = {};

        if (this.state.id != null && this.state.id !== "add") {

            loadedAccount = await GetAccount(this.state.id);

            if (!loadedAccount.error) {
                const account = loadedAccount.result;

                if (account != null && account.id != null) {
                    const accountRoles = await GetAccountRoles(account.id);
                    if (!accountRoles.error) {
                        account.roles = accountRoles.result;
                    }
                }

                this.setState(prevState => ({
                    ...prevState,
                    id: this.state.id,
                    editMode: this.state.editMode,
                    account: account,
                    originalAccount: account
                }));
            } else {
                this.setState(prevState => ({...prevState, error: loadedAccount}));
            }
        } else {
            this.setState((prevState) => ({
                ...prevState,
                id: null,
                account: {
                    id: null,
                    enabled: true,
                    accountType: 'DEFAULT',
                    country: "DE",
                    language: "de",
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false
                }
            }));
        }
    }

    async loadRecentlyUsedProducts() {
        if (this.state.id != null && this.state.id !== "add") {
            let recentlyUsedProducts = await GetRecentlyUsedProducts(this.state.account, {});
            if (!recentlyUsedProducts.error) {
                let account = this.state.account;
                account.recentlyUsedProducts = recentlyUsedProducts.result;

                this.setState(prevState => ({
                    ...prevState,
                    recentlyUsedProducts: recentlyUsedProducts.result,
                    account: account,
                    sections: {...prevState.sections, recentlyUsedProducts: true}
                }));
            } else {
                this.setState(prevState => ({...prevState, error: recentlyUsedProducts}));
            }
        }
    }

    async loadAvailableRoles() {
        let loadedRoles = await GetAllRoles();

        if (!loadedRoles.error) {
            let roles = loadedRoles.result;
            this.setState(prevState => ({...prevState, availableRoles: roles}));
        } else {
            this.setState(prevState => ({...prevState, error: loadedRoles}));
        }
    }

    async loadRestores(page) {
        let loadedRestores = await GetFilteredRestores({accountId: this.state.account.id, page: page});

        if (!loadedRestores.error) {
            let restores = loadedRestores.result;
            this.setState(prevState => ({
                ...prevState,
                accountRestores: restores,
                restorePage: page,
                lastRestorePage: Math.ceil(loadedRestores.length / 20)
            }));
        } else {
            this.setState(prevState => ({...prevState, error: loadedRestores}));
        }
    }

    async loadOrders(page) {
        let loadedOrders = await GetFilteredOrders({accountId: this.state.account.id, page: page});

        if (!loadedOrders.error) {
            let orders = loadedOrders.result;
            this.setState(prevState => ({
                ...prevState,
                accountOrders: orders,
                orderPage: page,
                lastOrderPage: Math.ceil(loadedOrders.length / 20)
            }));
        } else {
            this.setState(prevState => ({...prevState, error: loadedOrders}));
        }
    }

    async loadSubscriptions(page) {
        let loadedSubscriptions = await GetFilteredSubscriptions(this.state.account, {page: page, active_only: false});

        if (!loadedSubscriptions.error) {
            let subscriptions = loadedSubscriptions.result;
            this.setState(prevState => ({
                ...prevState,
                accountSubscriptions: subscriptions,
                subscriptionPage: page,
                lastSubscriptionPage: Math.ceil(loadedSubscriptions.length / 20)
            }));
        } else {
            this.setState(prevState => ({...prevState, error: loadedSubscriptions}));
        }
    }

    async loadAcquisitions(page) {
        let loadedAcquisitions = await GetFilteredAcquisitions(this.state.account, {active_only: false, page: page});

        if (!loadedAcquisitions.error) {
            let acquisitions = loadedAcquisitions.result;
            this.setState(prevState => ({
                ...prevState,
                accountAcquisitions: acquisitions,
                acquisitionPage: page,
                lastAcquisitionPage: Math.ceil(loadedAcquisitions.length / 20)
            }));
        } else {
            this.setState(prevState => ({...prevState, error: loadedAcquisitions}));
        }
    }

    async loadProfiles(page) {
        let loadedProfiles = await GetFilteredProfiles(this.state.account.id, {page: page});
        if (!loadedProfiles.error) {
            let users = loadedProfiles.result;
            this.setState(prevState => ({
                ...prevState,
                accountProfiles: users,
                userPage: page,
                lastUserPage: Math.ceil(loadedProfiles.length / 20)
            }));
        } else {
            this.setState(prevState => ({...prevState, error: loadedProfiles}));
        }
    }


    //----------
    //Rendering
    //----------

    render() {

        const labelStyle = {
            fontSize: "20px",
            backgroundColor: "#C8C8C8",
            borderStyle: "solid",
            borderColor: "#cdd7e0",
            borderWidth: "1px",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%"
        };

        return (
            <>
                <div className="details-title">
                    {this.state.account.id != null ? "Details of Account " + this.state.account.id : "Create a new Account"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "accounts", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.account.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}
                            type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        account: this.state.originalAccount
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateAccount()} />
                    }
                    <Button style={{float: "right", marginTop: "5px"}}
                            variant={this.state.editMode.expertMode ? "secondary" : "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    expertMode: !this.state.editMode.expertMode
                                }
                            }))}>Expert Mode</Button>
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}}
                            variant={this.state.editMode.autoSave ? "secondary" : "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Tools</div>
                <div className="details-box" style={{height: "70px"}}>
                    <button
                        className={this.state.account.id != null ? "form-btn-ci-light-blue" : "form-btn-ci-off"}
                        disabled={this.state.account.id == null}
                        onClick={() => this.setState(prevState => ({...prevState, showTicketGeneratorDialog: true}))}>Generiere tigerticket</button>
                    <button
                        className={(this.state.account.id != null && this.state.account.enabled) ? "form-btn-ci-red" : "form-btn-ci-off"}
                        style={{float: "right"}}
                        disabled={this.state.account.id == null || !this.state.account.enabled}
                        onClick={() => this.setState(prevState => ({...prevState, showDeletionDialog: true}))}>Account löschen</button>
                </div>

                <div className="details-box-title">Existing Contracts, subscriptions & more</div>
                <div className="details-box" style={{height: "70px"}}>
                    <Button style={{marginTop: "5px", marginRight: "10px"}}
                            variant={this.state.showRestores ? "secondary" : "outline-secondary"}
                            disabled={this.state.account.id == null}
                            onClick={() => {
                                if (!this.state.showRestores) {
                                    this.setState(prevState => ({
                                        ...prevState,
                                        loading: true,
                                        showOrders: false,
                                        showSubscriptions: false,
                                        showAcquisitions: false,
                                        showProfiles: false,
                                    }));
                                    this.loadRestores(1).then(r => this.setState(prevState => ({
                                        ...prevState,
                                        loading: false,
                                        showRestores: true
                                    })));
                                } else {
                                    this.setState(prevState => ({...prevState, showRestores: false}));
                                }
                            }}>
                        <VscListUnordered/>&#xA0;{this.state.showRestores ? "Hide Restores" : "Load Restores"}
                    </Button>
                    <Button style={{marginTop: "5px", marginRight: "10px"}}
                            variant={this.state.showOrders ? "secondary" : "outline-secondary"}
                            disabled={this.state.account.id == null}
                            onClick={() => {
                                if (!this.state.showOrders) {
                                    this.setState(prevState => ({
                                        ...prevState,
                                        loading: true,
                                        showRestores: false,
                                        showSubscriptions: false,
                                        showAcquisitions: false,
                                        showProfiles: false,
                                    }));
                                    this.loadOrders(1).then(r => this.setState(prevState => ({
                                        ...prevState,
                                        loading: false,
                                        showOrders: true
                                    })));
                                } else {
                                    this.setState(prevState => ({...prevState, showOrders: false}));
                                }
                            }}><VscListUnordered/>&#xA0;{this.state.showOrders ? "Hide Orders" : "Load Orders"}
                    </Button>
                    <Button style={{marginTop: "5px", marginRight: "10px"}}
                            variant={this.state.showSubscriptions ? "secondary" : "outline-secondary"}
                            disabled={this.state.account.id == null}
                            onClick={() => {
                                if (!this.state.showSubscriptions) {
                                    this.setState(prevState => ({
                                        ...prevState,
                                        loading: true,
                                        showRestores: false,
                                        showOrders: false,
                                        showAcquisitions: false,
                                        showProfiles: false,
                                    }));
                                    this.loadSubscriptions(1).then(r => this.setState(prevState => ({
                                        ...prevState,
                                        loading: false,
                                        showSubscriptions: true
                                    })));
                                } else {
                                    this.setState(prevState => ({...prevState, showSubscriptions: false}));
                                }
                            }}><VscListUnordered/>&#xA0;{this.state.showSubscriptions ? "Hide Subscriptions" : "Load Subscriptions"}
                    </Button>
                    <Button style={{marginTop: "5px", marginRight: "10px"}}
                            variant={this.state.showAcquisitions ? "secondary" : "outline-secondary"}
                            disabled={this.state.account.id == null}
                            onClick={() => {
                                if (!this.state.showAcquisitions) {
                                    this.setState(prevState => ({
                                        ...prevState,
                                        loading: true,
                                        showRestores: false,
                                        showOrders: false,
                                        showSubscriptions: false,
                                        showProfiles: false,
                                    }));
                                    this.loadAcquisitions(1).then(r => this.setState(prevState => ({
                                        ...prevState,
                                        loading: false,
                                        showAcquisitions: true
                                    })));
                                } else {
                                    this.setState(prevState => ({...prevState, showAcquisitions: false}));
                                }
                            }}><VscListUnordered/>&#xA0;{this.state.showAcquisitions ? "Hide Acquisitions" : "Load Acquisitions"}
                    </Button>
                    <Button style={{marginTop: "5px", marginRight: "10px"}}
                            variant={this.state.showProfiles ? "secondary" : "outline-secondary"}
                            disabled={this.state.account.id == null}
                            onClick={() => {
                                if (!this.state.showProfiles) {
                                    this.setState(prevState => ({
                                        ...prevState,
                                        loading: true,
                                        showRestores: false,
                                        showOrders: false,
                                        showSubscriptions: false,
                                        showAcquisitions: false,
                                    }));
                                    this.loadProfiles(1).then(r => this.setState(prevState => ({
                                        ...prevState,
                                        loading: false,
                                        showProfiles: true
                                    })));
                                } else {
                                    this.setState(prevState => ({...prevState, showProfiles: false}));
                                }
                            }}><VscListUnordered/>&#xA0;{this.state.showProfiles ? "Hide Profiles" : "Load Profiles"}
                    </Button>
                    {this.state.editMode.active &&
                    <div style={{float: "right"}}>
                        <Button style={{marginTop: "5px", marginRight: "10px"}}
                                variant="secondary" onClick={() => this.setState(prevState => ({
                            ...prevState,
                            showEmailDialog: true
                        }))}><AiOutlineMail/>&#xA0;Change E-Mail</Button>
                        <Button style={{marginTop: "5px"}}
                                variant="secondary" onClick={() => this.setState(prevState => ({
                            ...prevState,
                            showPasswordDialog: true
                        }))}><RiLockPasswordLine/>&#xA0;Change
                            Password</Button>
                    </div>
                    }
                </div>

                {this.state.showRestores &&
                <>
                    <div className="details-box-title">Restores</div>
                    <div className="details-box">
                        <AccountRestores restores={this.state.accountRestores} page={this.state.restorePage}
                                         lastPage={this.state.lastRestorePage}
                                         onLoadPage={(page) => this.loadRestores(page)}/>
                    </div>
                </>
                }

                {this.state.showOrders &&
                <>
                    <div className="details-box-title">Orders</div>
                    <div className="details-box">
                        <AccountOrders orders={this.state.accountOrders} page={this.state.orderPage}
                                       lastPage={this.state.lastOrderPage}
                                       onLoadPage={(page) => this.loadOrders(page)}/>
                    </div>
                </>
                }

                {this.state.showSubscriptions &&
                <>
                    <div className="details-box-title">Subscriptions</div>
                    <div className="details-box">
                        <AccountSubscriptions accountId={this.state.account.id}
                                              subscriptions={this.state.accountSubscriptions}
                                              page={this.state.subscriptionPage}
                                              lastPage={this.state.lastSubscriptionPage}
                                              onLoadPage={(page) => this.setState(prevState => ({
                                                  ...prevState,
                                                  subscriptionPage: page
                                              }))}/>
                    </div>
                </>
                }

                {this.state.showAcquisitions &&
                <>
                    <div className="details-box-title">Acquistions</div>
                    <div className="details-box">
                        <AccountAcquisitions accountId={this.state.account.id}
                                             acquisitions={this.state.accountAcquisitions}
                                             page={this.state.acquisitionPage} lastPage={this.state.lastAcquisitionPage}
                                             onLoadPage={(page) => this.setState(prevState => ({
                                                 ...prevState,
                                                 acquisitionPage: page
                                             }))}/>
                    </div>
                </>
                }

                {this.state.showProfiles &&
                <>
                    <div className="details-box-title">Profiles</div>
                    <div className="details-box">
                        <AccountProfiles accountId={this.state.account.id}
                                      profiles={this.state.accountProfiles}
                                      page={this.state.userPage} lastPage={this.state.lastUserPage}
                                      onLoadPage={(page) => this.setState(prevState => ({
                                          ...prevState,
                                          userPage: page
                                      }))}/>
                    </div>
                </>
                }

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="account"
                        fields={AccountMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateAccount()}
                        sectionId="general"
                    />

                    <Form>
                        <Row>
                            <Form.Group as={Col} controlId="accountRoles">
                                <Label style={labelStyle}>Roles</Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        value={this.state.account.roles}
                                        readOnly={true}/>

                                    {this.state.editMode.active &&
                                        <Button variant="outline-secondary" type="button"
                                                onClick={() => this.loadAvailableRoles().then(r => this.setState(prevState => ({
                                                    ...prevState,
                                                    showRolesDialog: true
                                                })))}>Change Roles</Button>
                                    }
                                </InputGroup>
                            </Form.Group>
                        </Row>
                    </Form>

                    {/* CUSTOMER DETAILS */}
                    <DetailsSection
                        nameInState="account"
                        fields={AccountMetaData.CUSTOMER_DETAILS}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="customerDetails"
                        onUpdateResource={() => this.saveOrUpdateAccount()}
                        label={<span><IoPeopleCircleOutline/>&#xA0;Customer Details</span>}
                    />

                    {/* TECHNICAL DETAILS */}
                    <DetailsSection
                        nameInState="account"
                        fields={AccountMetaData.TECHNICAL_DETAILS}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="technicalDetails"
                        onUpdateResource={() => this.saveOrUpdateAccount()}
                        label={<span><GrTechnology/>&#xA0;Technical Details</span>}
                    />

                    {/* Recently Used Products */}
                    <DetailsSection
                        nameInState="account"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="recentlyUsedProducts"
                        label={<span><BiSitemap/>&#xA0;Recently Used Products</span>}
                    />

                    {this.state.sections.recentlyUsedProducts &&
                    <Table responsive bordered hover striped>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Product Type</th>
                            <th>Title</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.recentlyUsedProducts.map(product => (
                            <tr role="row" key={product.id}>
                                <td><Link
                                    to={"/" + GlobalConstants.APP_PATH + "products/" + product.id}
                                    style={{fontStyle: "italic"}}>{product.id}</Link></td>
                                <td><Badge bg={
                                    product.productType === 'TIGERBOOK' ? "primary" :
                                        product.productType === 'MOVIE' ? "info" :
                                            product.productType === 'AUDIOBOOK' ? "light" :
                                                product.productType === 'BOOK' ? "success" : "dark"
                                }>{product.productType}</Badge></td>
                                <td>{product.title}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "accounts", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateAccount()} />
                    }
                </div>


                {/* DIALOGS */}
                <ActionModal show={this.state.showRolesDialog}
                             onHide={() => this.setState(prevState => ({...prevState, showRolesDialog: false}))}
                             onAction={() => this.updateRoles()}
                             actionButtonText="Save"
                             title={"Update Account Roles"}
                             body={this.rolesDialog()}/>

                <InfoModal show={this.state.showEmailDialog}
                           onHide={() => this.setState(prevState => ({
                               ...prevState,
                               account: {
                                   ...prevState.account,
                                   email: this.state.originalAccount.email
                               },
                               showEmailDialog: false
                           }))}
                           title={"Update account email"}
                           body={this.emailDialog()}/>

                <InfoModal show={this.state.showPasswordDialog}
                           onHide={() => this.setState(prevState => ({
                               ...prevState,
                               showPasswordDialog: false
                           }))}
                           title={"Update account password"}
                           body={this.passwordDialog()}/>

               <InfoModal show={this.state.showDeletionDialog}
                          title="Account löschen"
                          body={this.deletionModalBody()} />

               <InfoModal show={this.state.showTicketGeneratorDialog}
                          onHide={() => this.setState(prevState => ({
                              ...prevState,
                              showTicketGeneratorDialog: false
                          }))}
                          title={"DTT Generator"}
                          body={<DTTSingleGeneratorDialog
                              account={this.state.account}
                              finishProcess={() => {
                                  this.setState(prevState => ({...prevState, showTicketGeneratorDialog: false}));
                                  this.props.addToast("Der Mailversand wurde gestartet.", {
                                      autoDismiss: true,
                                      appearance: "success"
                                  });
                              }}
                              onSuccess={() => {
                                  this.props.addToast("Der Mailversand wurde erfolgreich abgeschlossen.", {
                                      autoDismiss: true,
                                      appearance: "success"
                                  });
                              }}
                              onError={() => {
                                  this.props.addToast("Beim Versand der Mails ist ein Fehler aufgetreten. Bitte schaue in den Logs des CRM-Services nach.", {
                                      appearance: "error",
                                      autoDismiss: true
                                  });
                              }}
                          />}
               />

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />

            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateAccount() {
        if (!RequiredFieldsAreValid('account', AccountMetaData.DETAILS_GENERAL, this.state, (s) => this.setState(s))) {
            return;
        }

        let account = this.state.account;
        if (account.pin != null && account.pin.trim().length === 0) {
            account.pin = null;
        }
        if (this.state.account.id != null) {
            account = await UpdateAccount(account);
        } else {
            account = await CreateAccount(account);
        }

        if (!account.error) {
            if (this.state.account.id == null) {
                this.props.history.push("/"+ GlobalConstants.APP_PATH + "accounts/" + account.result.id);
            }
            this.setState((prevState) => ({
                ...prevState,
                account: account.result,
                editMode: {...prevState.editMode, active: this.state.editMode.autoSave}
            }));

            this.props.addToast("The account has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: account}));
        }
    }

    async updateRoles() {
        let accountRoles = this.state.account.roles;
        let updatedRoles = [];
        for (let i = 0; i < accountRoles.length; i++) {
            updatedRoles.push({name: accountRoles[i]});
        }

        let response = await ChangeRoles(this.state.account, {roles: updatedRoles});

        if (!response.error) {
            this.props.addToast("Account roles have been updated successfully.", {
                autoDismiss: true,
                appearance: "success"
            });
            this.setState(prevState => ({...prevState, showRolesDialog: false}));
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    async updateEmail(email) {
        let response = await ChangeEmail(this.state.account, email);

        if (!response.error) {
            this.setState(prevState => ({...prevState, showEmailDialog: false}));
            this.props.addToast("Account email updated successfully.", {
                autoDismiss: true,
                appearance: "success"
            });
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    async updatePassword(password) {
        let response = await ChangePassword(this.state.account, password);

        if (!response.error) {
            this.setState(prevState => ({...prevState, showPasswordDialog: false}));
            this.props.addToast("Account password updated successfully.", {
                autoDismiss: true,
                appearance: "success"
            });
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    async deleteAccount() {
        if (this.state.account.id != null) {
            const response = await DeleteAccount(this.state.account);
            if (!response.error) {
                this.props.history.push("/"+ GlobalConstants.APP_PATH + "accounts");
            } else {
                this.props.addToast("Beim Löschen des Accounts ist ein Fehler aufgtereten: " + response.message, {
                    appearance: "error",
                    autoDismiss: true
                });
            }
        }
    }

    //--------
    // DIALOGS
    //--------

    deletionModalBody() {
        if (this.state.confirmDeletion) {
            return (
                <div style={{textAlign: "center"}}>
                    <h4>Achtung! Bei Klick auf "Account endgültig löschen" wird der ausgewählte Account gelöscht und die Daten anonymisiert. Dieser Schritt kann <span style={{color: "red"}}>nicht rückgängig gemacht werden!</span></h4>
                    <br />
                    <div style={{float: "right"}}>
                        <PromiseButton className="form-btn-ci-blue" text="Abbrechen" onClick={() => this.setState(prevState => ({
                            ...prevState,
                            showDeletionDialog: false,
                            confirmDeletion: false
                        }))}/>
                        <PromiseButton className="form-btn-ci-red" text="Account endgültig löschen" onClick={() => this.deleteAccount()}/>
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{textAlign: "center"}}>
                    <h4>Möchtest du den Account {this.state.account.email} wirklich löschen?</h4>
                    <br />
                    <div style={{float: "right"}}>
                        <PromiseButton className="form-btn-ci-blue" text="Abbrechen" onClick={() => this.setState(prevState => ({
                            ...prevState,
                            showDeletionDialog: false,
                            confirmDeletion: false
                        }))}/>
                        <PromiseButton className="form-btn-ci-red" text="Fortfahren" onClick={() => this.setState(prevState => ({
                            ...prevState,
                            confirmDeletion: true
                        }))}/>
                    </div>
                </div>
            );
        }
    }

    rolesDialog() {
        return (
            <>
                <Table responsive bordered striped hover>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Select</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.availableRoles.map(role => (
                        <tr role="row" key={role.name}
                            style={{cursor: "pointer"}}
                            onClick={() => this.toggleRole(role)}>
                            <td>{role.name}</td>
                            <td>
                                <input type="checkbox" checked={this.state.account.roles.indexOf(role.name) > -1}/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>
        );
    }

    emailDialog() {
        return (
            <>
                <Form>
                    <Row>
                        <Form.Group as={Col} controlId="accountEmail">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    value={this.state.account.email}
                                    onChange={(e) => this.setState(prevState => ({
                                        ...prevState,
                                        account: {...prevState.account, email: e.target.value}
                                    }))}/>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <PromiseButton text="Save" onClick={() => this.updateEmail(this.state.account.email)} />
                </Form>
            </>
        );
    }

    passwordDialog() {
        return (
            <>
                <Form>
                    <Row>
                        <Form.Group as={Col} controlId="accountPassword">
                            <Form.Control value={this.state.updatePasswordRequest}
                                          placeholder="Please provide a password (8 - 50 chars)"
                                          onChange={(e) => this.setState(prevState => ({
                                              ...prevState,
                                              updatePasswordRequest: e.target.value
                                          }))}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="accountConfirmPassword">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    value={this.state.updatePasswordConfirmRequest}
                                    placeholder="Please confirm the password"
                                    onChange={(e) => this.setState(prevState => ({
                                        ...prevState,
                                        updatePasswordConfirmRequest: e.target.value
                                    }))}/>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <PromiseButton text="Save" onClick={() => {
                        if (this.state.updatePasswordRequest === this.state.updatePasswordConfirmRequest) {
                            this.updatePassword(this.state.updatePasswordRequest).then(r => r);
                        } else {
                            this.props.addToast("The provided passwords don't match. Please check and try again.", {
                                autoDismiss: true,
                                appearance: "error"
                            });
                        }
                    }} />
                </Form>
            </>
        );
    }

    //--------
    // HELPERS
    //--------

    toggleRole(role) {
        let accountRoles = this.state.account.roles;

        const index = accountRoles.indexOf(role.name);

        if (index > -1) {
            //Role available -> Remove it
            accountRoles.splice(index, 1);
        } else {
            //Not available -> Add it
            accountRoles.push(role.name);
        }

        //Update the state
        this.setState(prevState => ({...prevState, account: {...prevState.account, roles: accountRoles}}));
    }
}


export default withToast(AccountDetails);