import {
    Button,
    ButtonGroup,
    Col,
    Dropdown,
    Form,
    FormCheck,
    FormControl,
    InputGroup,
    Pagination,
    Table
} from "react-bootstrap";
import React, {Component} from "react";
import FormSelect from "react-bootstrap/FormSelect";
import Row from 'react-bootstrap/Row';
import {withToast} from "../../util/ToastService";
import {PickTagDialog} from "../tags/PickTagDialog";
import {
    AddProductToProductGroup,
    CreateAssetProduct,
    CreateProduct,
    DeleteAssetProduct,
    GetAllProductGroups,
    GetAssetsOfProduct,
    GetProduct,
    RemoveProductFromProductGroup,
    UpdateAssetProduct,
    UpdateProduct,
    UpdateProductState,
    UploadAsset
} from "./ProductService";
import {Link} from "react-router-dom";
import {
    BiAddToQueue,
    BsImages,
    BsTrash,
    FaBook,
    FaLayerGroup,
    FaReact, FcSerialTasks,
    GoFileDirectory,
    GoSettings,
    GrEdit,
    GrView,
    HiOutlineDocumentReport,
    IoIosArrowDropdown,
    IoIosArrowDropup,
    MdDone,
    TiDeleteOutline
} from "react-icons/all";
import {GetFilteredImprints, GetImprint} from "../imprints/ImprintService";
import {Label} from "reactstrap";
import {ActionModal, InfoModal, TableModal} from "../../generators/ModalGenerator";
import {GetFilteredCategories} from "../categories/CategoryService";
import GlobalConstants from "../../config/GlobalConstants";
import {copyUrlToClipboardColumn} from "../../generators/TableGenerator";
import {openExternalTargetInNewTab} from "../../util/ResourceService";
import {AddOrEditAssetProductDialog} from "./AddOrEditAssetProductDialog";
import {
    DeleteEpisode,
    GetEpisodesByProductId,
    GetResourceByUrl,
    UpdateEpisode
} from "../series/SeriesService";
import SeriesMetaData from "../series/SeriesMetaData";
import {AddProductToSeriesDialog} from "./AddProductToSeriesDialog";
import {DetailsSection, RequiredFieldsAreValid} from "../../generators/DetailsGenerator";
import ProductMetaData, {listOfValidIapProductIdentifier, ProductAttributesByType} from "./ProductMetaData";
import {GetReportingProduct, UpdateReportingProduct} from "../../reporting/reportingProduct/ReportingProductService";
import {EditReportingProductDialog} from "../../reporting/reportingProduct/EditReportingProductDialog";
import {
    GetFilteredRecommendationsProducts,
    ToggleRecommendationByProduct,
    UpdateRecommendationProduct
} from "../recommendation/RecommendationService";
import RecommendationMetaData from "../recommendation/RecommendationMetaData";
import {ErrorHandler} from "../../util/ErrorHandler";
import {iso6392} from 'iso-639-2'
import {LoadingIndicator, PromiseButton} from "../../global/SpinningTiger";
import {PickAssetDialog} from "./PickAssetDialog";
import {GetFilteredAssets} from "../assets/AssetService";

class ProductDetails extends Component {

    constructor(props) {

        super(props);

        this.newKeyword = "";
        this.newEan = "";
        this.newPrice = {
            countryCode: "",
            amount: 0,
            currencyCode: "",
        }
        this.addCategoriesMode = false;
        this.selectedProductGroup = 0;
        this.selectedAsset = {};
        this.selectedAssetProduct = {};
        this.selectedSeason = {};

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            product: {
                assetProducts: [],
                assets: [],
                productGroupIds: [],
            },
            recommendations: [],
            recommendationNew: {},
            recommendationSimilar: {},
            changeProductStateTo: "ACTIVE",
            originalProduct: {},
            productAttributes: {},
            series: {
                data: {
                    seasons: []
                },
                filter: {
                    page: 1,
                    query: "",
                },
                showDialog: false,
            },
            season: {},
            episodes: [],
            episode: {
                showDialog: false,
            },
            categories: {
                data: [],
                filter: {
                    page: 1,
                    query: "",
                },
                showDialog: false,
            },
            tags: {
                showDialog: false,
            },
            imprints: {
                data: [],
                filter: {
                    page: 1,
                    name: "",
                },
                showDialog: false,
            },
            productGroups: {
                data: [],
                filter: {
                    page: 1,
                    name: "",
                },
                showDialog: false,
            },
            assetProducts: {
                showDialog: false
            },
            assets: [],
            showValidIapsDialog: false,
            validIaps: [],
            showReportingProductDialog: false,
            reportingProduct: {},
            aggregator: {},
            productType: null,
            sections: {
                general: true,
                contentDetails: true,
                productGroups: false,
                series: true,
                assets: true,
                technicalDetails: false,
                appearance: false,
                classifications: true,
                reporting: false,
                variousAttributes: false
            },

            deletionModals: {
                productGroup: false,
                assetProduct: false,
                episode: false,
                allEpisodes: false,
            },
            pickAssetDialog: false,
            showLanguageDialog: false,
            isos: [],
            filteredIsos: [],
            selectedAttribute: null,
            validationErrors: []
        }
    }

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Product " + this.state.id  + " :: Tiger UI";
        } else {
            document.title = "New product :: Tiger UI";
        }

        //Make sure the page is opened at top
        window.scrollTo(0,0);

        await this.loadProduct();

        this.setState(prevState => ({...prevState, isos: iso6392, filteredIsos: iso6392}));
    }

    async loadProduct(){
        //Get the list of valid IAP product identifier for products with type = 'Subscription'
        const validIaps = listOfValidIapProductIdentifier();

        //Load the product (if it exists - create it otherwise)
        let loadedProduct = {};

        if (this.state.id != null && this.state.id !== "add") {

            loadedProduct = await GetProduct(this.state.id);

            if (!loadedProduct.error) {
                let product = loadedProduct.result;

                //Load the imprint
                const loadedImprint = await GetImprint(product.imprintId);
                if (!loadedImprint.error) {
                    product.imprint = loadedImprint.result;
                } else {
                    product.imprint = {};
                }

                //Load the assets
                GetAssetsOfProduct(product).then(result => {
                    this.setState({
                        id: this.state.id,
                        editMode: this.state.editMode,
                        validIaps: validIaps,
                        product: result,
                        productType: result.productType,
                        originalProduct: result,
                        productAttributes: result.attributes,
                        categories: this.state.categories,
                        tags: this.state.tags,
                        imprints: this.state.imprints,
                        productGroups: this.state.productGroups,
                        sections: this.state.sections,
                        deletionModals: this.state.deletionModals
                    });
                });

                //Load Series Details
                GetEpisodesByProductId(this.state.id).then(result => {
                    if (!result.error && result.result !== null && result.result.length > 0) {
                        //There is at least one episode -> Fetch the details of the series
                        this.setState(prevState => ({
                            ...prevState,
                            episodes: result.result
                        }));
                        let seriesUrl = result.result[0]._links.series.href;
                        let seasonUrl = result.result[0]._links.season.href;
                        GetResourceByUrl(seriesUrl).then(series => {
                            if (!series.error) {
                                GetResourceByUrl(seasonUrl).then(season => {
                                    if (!season.error)
                                    {
                                        this.setState(prevState => ({
                                            ...prevState,
                                            series: {
                                                ...prevState.series,
                                                data: series.result
                                            },
                                            season: season.result
                                        }));
                                    }
                                });
                            }
                        })
                    }
                });

                await this.loadReportingProduct();

                //Load the recommendation
                GetFilteredRecommendationsProducts({productId: this.state.id})
                    .then(response => {
                        let recommendationNew = {};
                        let recommendationSimilar = {};
                       if(!response.error) {
                            let result = response.result;
                            for(let i = 0; i < result.length; i++) {
                                let r = result[i];
                                if(r.notificationType === RecommendationMetaData.RECOMMENDATION_PRODUCT_TYPES[0].id) {
                                    recommendationNew = r;
                                }
                                if(r.notificationType === RecommendationMetaData.RECOMMENDATION_PRODUCT_TYPES[1].id) {
                                    recommendationSimilar = r;
                                }
                            }
                       }
                       this.setState(prevState => ({...prevState, recommendationNew: recommendationNew, recommendationSimilar: recommendationSimilar}));
                    });

                //Load the reporting product
                //let loadedReportingProduct = await GetReportingProduct(this.state.id);
                //if(!loadedReportingProduct.error) {
                //    this.setState(prevState => ({...prevState, reportingProduct: loadedReportingProduct.result}));
                //}

            } else {
                this.setState(prevState => ({...prevState, error: loadedProduct}));
            }
        } else {
            this.setState((prevState) => ({
                ...prevState,
                id: null,
                product: {
                    id: null,
                    assetProducts: [],
                    assets: [],
                    productGroupIds: [],
                    attributes: ProductAttributesByType(ProductMetaData.PRODUCT_TYPES[0]),
                    productType: ProductMetaData.PRODUCT_TYPES[0],
                    productClassification: ProductMetaData.PRODUCT_CLASSIFICATIONS[0],
                    state: ProductMetaData.PRODUCT_STATES[0],
                },
                productType: ProductMetaData.PRODUCT_TYPES[0],
                validIaps: validIaps,
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }
            }));
        }

        //Now load the product groups
        let loadedProductGroups = await GetAllProductGroups();
        if (!loadedProductGroups.error) {
            this.setState(prevState => ({
                ...prevState,
                productGroups: {
                    ...prevState.productGroups,
                    data: loadedProductGroups.result
                }
            }));
        } else {
            this.setState(prevState => ({...prevState, error: loadedProductGroups}));
        }
    }

    reloadAttributes(prevState, state) {
        return state;
    }

    render() {

        const productEans = (
            <div>
                {this.state.product.eans !== undefined && this.state.editMode.active && this.state.product.eans.map(ean => (
                    <button className="form-btn-ci" type="button" onClick={() => {
                        let updatedEans = this.state.product.eans.filter(e => e !== ean);
                        this.setState(prevState => ({
                            ...prevState,
                            product: {
                                ...prevState.product,
                                eans: updatedEans
                            }
                        }))
                    }}>{ean}&#xA0;<TiDeleteOutline/></button>
                ))}
                {this.state.product.eans !== undefined && !this.state.editMode.active && this.state.product.eans.map(ean => (
                    <button className="form-btn-ci" disabled={true}>{ean}</button>
                ))}
            </div>
        );

        const productCategories = (
            <div>
                {this.state.product.categories !== undefined && this.state.editMode.active && this.state.product.categories.map(category => (
                    <button className="form-btn-ci" type="button" style={{marginBottom: "5px"}} onClick={() => {
                        let updatedCategories = this.state.product.categories.filter(cat => cat.id !== category.id);
                        this.setState(prevState => ({
                            ...prevState,
                            product: {
                                ...prevState.product,
                                categories: updatedCategories
                            }
                        }))
                    }
                    }>{category.name}&#xA0;<TiDeleteOutline/></button>
                ))}
                {this.state.product.categories !== undefined && !this.state.editMode.active && this.state.product.categories.map(category => (
                    <button className="form-btn-ci" disabled={true}>{category.name}</button>
                ))}
            </div>
        );

        const similarityProductCategory = (
            <div>
                {this.state.product.similarityCategory != null && this.state.editMode.active && this.state.product.similarityCategory.id != null &&
                <div>
                    <button className="form-btn-ci">{this.state.product.similarityCategory.name}</button>
                    <span style={{float: "right"}}>
                            <Button variant="outline-secondary" type="button" onClick={() => {
                                this.loadCategoryPage(1).then(r => {
                                    this.addCategoriesMode = false;
                                    this.setState(prevState => ({
                                        ...prevState,
                                        categories: {...prevState.categories, showDialog: true}
                                    }));
                                });
                            }}>Change</Button>
                        </span>
                </div>
                }
                {this.state.product.similarityCategory != null && !this.state.editMode.active && this.state.product.similarityCategory.id != null &&
                <button className="form-btn-ci" disabled={true}>{this.state.product.similarityCategory.name}</button>
                }
            </div>
        );

        const productTags = (
            <div>
                {this.state.product.tags !== undefined && this.state.editMode.active && this.state.product.tags.map(tag => (
                    <button className="form-btn-ci" type="button" onClick={() => {
                        let updatedTags = this.state.product.tags.filter(tg => tg.id !== tag.id);
                        this.setState(prevState => ({
                            ...prevState,
                            product: {
                                ...prevState.product,
                                tags: updatedTags
                            }
                        }))
                    }}>{tag.name}&#xA0;<TiDeleteOutline/></button>
                ))}
                {this.state.product.tags !== undefined && !this.state.editMode.active && this.state.product.tags.map(tag => (
                    <button className="form-btn-ci" disabled={true}>{tag.name}</button>
                ))}
            </div>
        );

        const productKeywords = (
            <div>
                {this.state.product.keywords !== undefined && this.state.editMode.active && this.state.product.keywords.map(keyword => (
                    <button className="form-btn-ci" type="button" onClick={() => {
                        let updatedKeywords = this.state.product.keywords.filter(kw => kw !== keyword);
                        this.setState(prevState => ({
                            ...prevState,
                            product: {
                                ...prevState.product,
                                keywords: updatedKeywords
                            }
                        }))
                    }}>{keyword}&#xA0;<TiDeleteOutline/></button>
                ))}
                {this.state.product.keywords !== undefined && !this.state.editMode.active && this.state.product.keywords.map(keyword => (
                    <button className="form-btn-ci" disabled={true}>{keyword}</button>
                ))}
            </div>
        );

        const productPrices = (
            <div>
                <ul style={{listStyle: "none"}}>
                    {this.state.product.prices !== undefined && !this.state.editMode.active && this.state.product.prices.map(price => (
                        <li>{price.countryCode} - {price.amount} {price.currencyCode}</li>
                    ))}
                    {this.state.product.prices !== undefined && this.state.editMode.active && this.state.product.prices.map(price => (
                        <li style={{height: "40px"}}>
                            {price.countryCode} - {price.amount} {price.currencyCode}
                            <button className="form-btn-ci" type="button" style={{
                                height: "30px",
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                paddingTop: "0",
                                paddingBottom: "0"
                            }} onClick={() => {
                                let updatedPrices = this.state.product.prices.filter(pr => pr.countryCode !== price.countryCode);
                                this.setState(prevState => ({
                                    ...prevState,
                                    product: {
                                        ...prevState.product,
                                        prices: updatedPrices
                                    }
                                }))
                            }}>x
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        );

        const labelStyle = {
            fontSize: "20px",
            backgroundColor: "#C8C8C8",
            borderStyle: "solid",
            borderColor: "#cdd7e0",
            borderWidth: "1px",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%"
        };

        const labelGroupStyle = {
            fontSize: "20px",
            borderStyle: "solid",
            borderColor: "#cdd7e0",
            borderWidth: "1px",
            borderRadius: "5px",
            padding: "10px",
            width: "100%"
        };


        return (
            <>
                <div className="details-title">
                    {this.state.product.id != null ? "Details of Product " + this.state.product.id : "Create a new product"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "products", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.product.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}
                            type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        product: this.state.originalProduct
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateProduct()}/>
                    }
                    <Dropdown as={ButtonGroup} style={{marginLeft: "50px", float: "left"}} drop="down">
                        <Button style={{
                            paddingTop: "8px",
                            paddingBottom: "10px",
                            marginBottom: "10px",
                            height: "40px"
                        }}
                                variant={this.state.changeProductStateTo === 'NEW' ? "primary" : this.state.changeProductStateTo === 'TAKEDOWN' ? "danger" : this.state.changeProductStateTo === 'INACTIVE' ? "warning" : "success"}
                                onClick={() => this.updateProductState()}><LoadingIndicator
                            text={"Change State To " + this.state.changeProductStateTo}/></Button>
                        <Dropdown.Toggle style={{marginBottom: "10px"}} split
                                         variant={this.state.changeProductStateTo === 'NEW' ? "outline-primary" : this.state.changeProductStateTo === 'TAKEDOWN' ? "outline-danger" : this.state.changeProductStateTo === 'INACTIVE' ? "outline-warning" : "outline-success"}/>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() => this.setState(prevState => ({
                                    ...prevState,
                                    changeProductStateTo: 'NEW'
                                }))}>New</Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => this.setState(prevState => ({
                                    ...prevState,
                                    changeProductStateTo: 'ACTIVE'
                                }))}>Active</Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => this.setState(prevState => ({
                                    ...prevState,
                                    changeProductStateTo: 'INACTIVE'
                                }))}>Inactive</Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => this.setState(prevState => ({
                                    ...prevState,
                                    changeProductStateTo: 'TAKEDOWN'
                                }))}>Takedown</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <div style={{
                        marginLeft: "50px",
                        marginTop: "10px",
                        fontWeight: "bold",
                        float: "left",
                        display: "block"
                    }}>
                        Recommend as:
                    </div>
                    <div style={{marginLeft: "15px", display: "inline-block", float: "left"}}>
                        <Form.Check type="checkbox" style={{display: "inline-block"}}
                                    readOnly={!this.recommendationAllowed()}
                                    disabled={!this.recommendationAllowed()}
                                    label={"New Product"}
                                    value={this.state.recommendationNew.notificationStatus !== undefined && this.state.recommendationNew.notificationStatus !== "ABORTED"}
                                    checked={this.state.recommendationNew.notificationStatus !== undefined && this.state.recommendationNew.notificationStatus !== "ABORTED"}
                                    onChange={(e) => {
                                        ToggleRecommendationByProduct(this.state.recommendationNew, this.state.product, RecommendationMetaData.RECOMMENDATION_PRODUCT_TYPES[0].id)
                                            .then(r => {
                                                if (!r.error) {
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        recommendationNew: r.result
                                                    }));
                                                } else {
                                                    this.setState(prevState => ({...prevState, error: r}));
                                                }
                                            })
                                    }}
                        /><br/>
                        <Form.Check type="checkbox" style={{display: "inline-block"}} readOnly={true}
                                    disabled={true}
                                    label={"Similar Product"}
                                    value={this.state.recommendationSimilar.notificationStatus !== undefined && this.state.recommendationSimilar.notificationStatus !== "ABORTED"}
                                    checked={this.state.recommendationSimilar.notificationStatus !== undefined && this.state.recommendationSimilar.notificationStatus !== "ABORTED"}
                                    onClick={(e) => ToggleRecommendationByProduct(this.state.recommendationSimilar, this.state.product, RecommendationMetaData.RECOMMENDATION_PRODUCT_TYPES[1].id)
                                        .then(r => {
                                            if (!r.error) {
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    recommendationSimilar: r.result
                                                }));
                                            } else {
                                                this.setState(prevState => ({...prevState, error: r}));
                                            }
                                        })}
                        >
                        </Form.Check>
                    </div>
                    <Button style={{float: "right", marginTop: "5px"}}
                            variant={this.state.editMode.expertMode ? "secondary" : "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    expertMode: !this.state.editMode.expertMode
                                }
                            }))}>Expert Mode</Button>
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}}
                            variant={this.state.editMode.autoSave ? "secondary" : "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    <DetailsSection
                        nameInState="product"
                        fields={ProductMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateProduct()}
                        onTrackedFieldUpdated={(id) => {
                            if (id === 'productType') {
                                this.setState(prevState => ({
                                    ...prevState,
                                    product: {
                                        ...prevState.product,
                                        attributes: ProductAttributesByType(this.state.product.productType)
                                    }
                                }));
                            }
                        }}
                        sectionId="general"
                    />

                    <DetailsSection
                        nameInState="product"
                        fields={ProductMetaData.DETAILS_CONTENT}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="contentDetails"
                        onUpdateResource={() => this.saveOrUpdateProduct()}
                        label={<span><FaBook/>&#xA0;Content Details</span>}
                    />

                    {this.state.sections.contentDetails &&
                    <Row className={"details-row"}>
                        <Form.Group as={Col} controlId="productImprint">
                            <Label style={labelStyle}>Imprint*</Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    isInvalid={this.state.validationErrors.indexOf("imprintId") > -1}
                                    value={this.state.product.imprint !== undefined ? this.state.product.imprint.name : ""}
                                    readOnly={!this.state.editMode.active}/>

                                {this.state.editMode.active &&
                                    <Button variant="outline-secondary" type="button" onClick={() => {
                                        this.loadImprintsPage(1).then(r => {
                                            this.setState(prevState => ({
                                                ...prevState,
                                                imprints: {...prevState.imprints, showDialog: true}
                                            }));
                                        });
                                    }}>Pick an imprint</Button>
                                }
                                <Form.Control.Feedback type="invalid">
                                    The imprint is required
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    }
                    <Form>

                        <hr/>
                        <h4 style={{cursor: "pointer"}} onClick={() => this.setState(prevState => ({
                            ...prevState,
                            sections: {...prevState.sections, classifications: !this.state.sections.classifications}
                        }))}><GoFileDirectory/>&#xA0;Classifications
                            <span style={{float: "right", marginRight: "10px", cursor: "pointer"}}
                                  onClick={() => this.setState(prevState => ({
                                      ...prevState,
                                      sections: {
                                          ...prevState.sections,
                                          classifications: !this.state.sections.classifications
                                      }
                                  }))}>{this.state.sections.classifications ? <IoIosArrowDropup/> :
                                <IoIosArrowDropdown/>}</span></h4>
                        <hr/>
                        {this.state.sections.classifications &&
                        <>
                            <Row className={"details-row"}>
                                <Form.Group as={Col} controlId="productAgeMin">
                                    <Label style={labelStyle}>Age Min</Label>
                                    <Form.Control type="number" value={this.state.product.ageMin}
                                                  readOnly={!this.state.editMode.active}
                                                  onChange={(e) => this.setState(prevState => ({
                                                      product: {
                                                          ...prevState.product,
                                                          ageMin: e.target.value
                                                      }
                                                  }))}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="productPopularity">
                                    <Label style={labelStyle}>Popularity</Label>
                                    <Form.Control type="number" value={this.state.product.popularity}
                                                  readOnly={!this.state.editMode.active}
                                                  onChange={(e) => this.setState(prevState => ({
                                                      product: {
                                                          ...prevState.product,
                                                          popularity: e.target.value
                                                      }
                                                  }))}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="productFeaturedRank">
                                    <Label style={labelStyle}>Featured Rank</Label>
                                    <Form.Control type="number" value={this.state.product.featuredRank}
                                                  readOnly={!this.state.editMode.active}
                                                  onChange={(e) => this.setState(prevState => ({
                                                      product: {
                                                          ...prevState.product,
                                                          featuredRank: e.target.value
                                                      }
                                                  }))}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="productLanguage">
                                    <Label style={labelStyle}>Language</Label>
                                    <InputGroup>
                                        <Form.Control type="text" value={this.state.product.language}
                                                      onChange={(e) => this.setState(prevState => ({
                                                          product: {
                                                              ...prevState.product,
                                                              language: e.target.value
                                                          }
                                                      }))}
                                                      readOnly={true}/>
                                        {this.state.editMode.active &&
                                            <Button variant="outline-secondary"
                                                    onClick={() => this.setState(prevState => ({
                                                        ...prevState,
                                                        showLanguageDialog: true
                                                    }))}>Pick</Button>
                                        }
                                    </InputGroup>
                                </Form.Group>
                            </Row>
                            <Row className={"details-row"}>
                                <Form.Group as={Col} controlId="productCategories">
                                    <Label style={labelStyle}>Categories</Label>
                                    {this.state.editMode.active &&
                                    <Button variant="outline-secondary"
                                            style={{height: "42px", marginRight: "5px", float: "left"}} onClick={() => {
                                        this.loadCategoryPage(1).then(r => {
                                            this.addCategoriesMode = true;
                                            this.setState(prevState => ({
                                                ...prevState,
                                                categories: {...prevState.categories, showDialog: true}
                                            }));
                                        });
                                    }}><BiAddToQueue size={18}/> Add Categories</Button>
                                    }
                                    {productCategories}
                                </Form.Group>
                                <Form.Group as={Col} controlId="productLegalNotice">
                                    <Label style={labelStyle}>Legal Notice</Label>
                                    <Form.Control value={this.state.product.legalNotice}
                                                  readOnly={!this.state.editMode.active}
                                                  onChange={(e) => this.setState(prevState => ({
                                                      product: {
                                                          ...prevState.product,
                                                          legalNotice: e.target.value
                                                      }
                                                  }))}/>
                                </Form.Group>
                            </Row>
                            {this.state.editMode.expertMode &&
                            <>
                                <Row className={"details-row"}>
                                    <Form.Group as={Col} controlId="productSubjectCode">
                                        <Label style={labelStyle}>Subject Code</Label>
                                        <Form.Control value={this.state.product.subjectCode}
                                                      readOnly={!this.state.editMode.active}
                                                      onChange={(e) => this.setState(prevState => ({
                                                          product: {
                                                              ...prevState.product,
                                                              subjectCode: e.target.value
                                                          }
                                                      }))}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="productArticleNumber">
                                        <Label style={labelStyle}>Article Number</Label>
                                        <Form.Control value={this.state.product.articleNumber}
                                                      readOnly={!this.state.editMode.active}
                                                      onChange={(e) => this.setState(prevState => ({
                                                          product: {
                                                              ...prevState.product,
                                                              articleNumber: e.target.value
                                                          }
                                                      }))}/>
                                    </Form.Group>
                                </Row>
                                <Row className={"details-row"}>
                                    <Form.Group as={Col} controlId="productEdition">
                                        <Label style={labelStyle}>Edition</Label>
                                        <Form.Control value={this.state.product.edition}
                                                      readOnly={!this.state.editMode.active}
                                                      onChange={(e) => this.setState(prevState => ({
                                                          product: {
                                                              ...prevState.product,
                                                              edition: e.target.value
                                                          }
                                                      }))}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="productSimilarityCategory">
                                        <Label style={labelStyle}>Similarity Category</Label>
                                        {similarityProductCategory}
                                    </Form.Group>
                                </Row>
                            </>
                            }

                            <Row className={"details-row"}>
                                    <Form.Group as={Col} controlId="productTags">
                                        <Label style={labelStyle}>Tags</Label>
                                        {this.state.editMode.active &&
                                                <Button variant="outline-secondary"
                                                        style={{height: "42px", marginRight: "5px", float: "left"}} onClick={() => {
                                                    this.loadTagsPage(1).then(r => {
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            tags: {...prevState.tags, showDialog: true}
                                                        }));
                                                    });
                                                }}><BiAddToQueue size={18}/> Add Tags</Button>
                                        }
                                        {productTags}
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="productKeywords">
                                        <Label style={labelStyle}>Keywords</Label>
                                        {this.state.editMode.active &&
                                        <InputGroup className="mb-3">
                                            <FormControl
                                                placeholder="Add a new keyword"
                                                onChange={(e) => this.newKeyword = e.target.value}
                                            />
                                                <Button variant="outline-secondary" onClick={() => {
                                                    if (!this.state.product.keywords.includes(this.newKeyword) && this.newKeyword !== "") {
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            product: {
                                                                ...prevState.product,
                                                                keywords: this.state.product.keywords.concat(this.newKeyword)
                                                            }
                                                        }));
                                                    } else {
                                                        this.props.addToast("This keyword already exists or is not valid.", {
                                                            autoDismiss: true,
                                                            appearance: 'warning'
                                                        });
                                                    }
                                                }}>Add</Button>
                                        </InputGroup>
                                        }
                                        {productKeywords}
                                    </Form.Group>
                                </Row>
                            {this.state.editMode.expertMode &&
                                <>

                                <Row className={"details-row"}>
                                    <Form.Group as={Col} controlId="productRecommendation">
                                        <Label style={labelStyle}>Recommendation</Label>
                                        <Form.Control value={this.state.product.recommendation}
                                                      readOnly={!this.state.editMode.active}
                                                      onChange={(e) => this.setState(prevState => ({
                                                          product: {
                                                              ...prevState.product,
                                                              recommendation: e.target.value
                                                          }
                                                      }))}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="productAgeMax">
                                        <Label style={labelStyle}>Age Max</Label>
                                        <Form.Control type="number" value={this.state.product.ageMax}
                                                      readOnly={!this.state.editMode.active}
                                                      onChange={(e) => this.setState(prevState => ({
                                                          product: {
                                                              ...prevState.product,
                                                              ageMax: e.target.value
                                                          }
                                                      }))}/>
                                    </Form.Group>
                                </Row>
                            </>
                            }
                        </>
                        }

                        <hr/>
                        <h4 style={{cursor: "pointer"}} onClick={() => this.setState(prevState => ({
                            ...prevState,
                            sections: {...prevState.sections, assets: !this.state.sections.assets}
                        }))}><BsImages/>&#xA0;Assets
                            <span style={{float: "right", marginRight: "10px", cursor: "pointer"}}
                                  onClick={() => this.setState(prevState => ({
                                      ...prevState,
                                      sections: {
                                          ...prevState.sections,
                                          assets: !this.state.sections.assets
                                      }
                                  }))}>{this.state.sections.assets ? <IoIosArrowDropup/> :
                                <IoIosArrowDropdown/>}</span></h4>
                        <hr/>
                        {this.state.sections.assets &&
                        <>
                            <PromiseButton type="button" className="form-btn-ci" style={{marginBottom: "15px"}}
                                           disabled={this.state.product.id == null}
                                           onClick={() => {
                                               this.selectedAssetProduct = {};
                                               this.selectedAsset = {};
                                               this.setState(prevState => ({
                                                   ...prevState,
                                                   assetProducts: {...prevState.assetProducts, showDialog: true}
                                               }));
                                           }} text="Upload new Asset"/>
                            <PromiseButton className="form-btn-ci" style={{marginBottom: "25px"}}
                                           disabled={this.state.product.id == null}
                                           onClick={() => GetFilteredAssets({page: 1, query: null}).then(r => {
                                               if (!r.error) {
                                                   this.setState(prevState => ({
                                                       ...prevState,
                                                       assets: r.result,
                                                       pickAssetDialog: true
                                                   }));
                                               } else {
                                                   this.props.addToast("An error occurred during loading the assets: " + r.message, {
                                                       autoDismiss: true,
                                                       appearance: "error"
                                                   });
                                               }
                                           })} text="Add existing Asset"/>
                            <br/>
                            <br/>
                            {this.state.product.assets != null &&
                            <Table responsive bordered striped hover>
                                <thead>
                                <tr>
                                    <th>Asset ID</th>
                                    <th>AssetProduct Type</th>
                                    <th>Preview</th>
                                    <th>URL</th>
                                    <th>Manage</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.product.assets.map(asset => (
                                    <tr role="row" key={asset.id}>
                                        <td>
                                            <a style={{color: "#333", fontStyle: "italic"}}
                                               href={GlobalConstants.SPINE_CLIENT_HOST + GlobalConstants.APP_PATH_ASSET_DETAILS + asset.id}>{asset.id}</a>
                                        </td>
                                        <td>{this.state.product.assetProducts.filter(assetProduct => assetProduct.assetId === asset.id)[0].assetProductType}</td>
                                        <td>
                                            {this.state.product.assetProducts.filter(assetProduct => assetProduct.assetId === asset.id)[0].assetProductType.includes("COVER") ?
                                                <img src={asset.url} alt={"Preview of asset " + asset.id}
                                                     style={{width: "100px", height: "100px", cursor: "pointer"}}
                                                     onClick={() => openExternalTargetInNewTab(asset.url)}/>
                                                :
                                                <a href={asset.url}><Button
                                                    variant="outline-secondary">Download</Button></a>
                                            }
                                        </td>
                                        <td>{copyUrlToClipboardColumn(asset, this.props)}</td>
                                        <td>
                                            <Button variant="info" onClick={() => {
                                                this.selectedAsset = asset;
                                                this.selectedAssetProduct = this.state.product.assetProducts.filter(assetProduct => assetProduct.assetId === asset.id)[0];
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    assetProducts: {...prevState.assetProducts, showDialog: true}
                                                }));
                                            }}><GrEdit/></Button>
                                            <span style={{margin: "5px"}}></span>

                                            <Button variant="danger" onClick={() => {
                                                this.selectedAsset = asset;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    deletionModals: {
                                                        ...prevState.deletionModals,
                                                        assetProduct: true
                                                    }
                                                }));
                                            }}><BsTrash/></Button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                            }
                        </>
                        }

                        <hr/>
                        <h4 style={{cursor: "pointer"}} onClick={() => this.setState(prevState => ({
                            ...prevState,
                            sections: {...prevState.sections, series: !this.state.sections.series}
                        }))}><FcSerialTasks />&#xA0;Series
                            <span style={{float: "right", marginRight: "10px", cursor: "pointer"}}
                                  onClick={() => this.setState(prevState => ({
                                      ...prevState,
                                      sections: {
                                          ...prevState.sections,
                                          series: !this.state.sections.series
                                      }
                                  }))}>{this.state.sections.series ? <IoIosArrowDropup/> :
                                <IoIosArrowDropdown/>}</span></h4>
                        <hr/>
                        {this.state.sections.series &&
                        <>
                            <span style={{width: "100%"}}>
                                <button type="button" className="form-btn-ci"
                                        onClick={() => {
                                            if (this.state.series.data.id == null) {
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    series: {...prevState.series, showDialog: true}
                                                }));
                                            } else {
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    deletionModals: {...prevState.deletionModals, allEpisodes: true}
                                                }));
                                            }
                                        }}>{this.state.series.data.id != null ? "Remove" : "Add"}</button>
                            </span>

                            <br/>
                            <br/>
                            <br/>

                            {this.state.series.data.id != null &&
                            <>
                                <h5 style={labelGroupStyle}>Series Details</h5>
                                <Row className={"details-row"}>
                                    <Form.Group as={Col} controlId="seriesId">
                                        <Label style={labelStyle}>Series ID</Label>
                                        <InputGroup>
                                            <Form.Control type="number" value={this.state.series.data.id}
                                                          readOnly={true}/>
                                            <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "series/" + this.state.series.data.id}}>
                                                <Button variant="outline-secondary">Open</Button>
                                            </Link>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="seriesName">
                                        <Label style={labelStyle}>Name</Label>
                                        <Form.Control value={this.state.series.data.name}
                                                      readOnly={true}
                                                      />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="visibility">
                                        <Label style={labelStyle}>Visibility</Label>
                                        <Button variant={this.state.series.data.visibility === 'VISIBLE' ? 'success' : 'danger'}>{this.state.series.data.visibility}</Button>
                                    </Form.Group>
                                </Row>

                                    <Row className={"details-row"}>
                                        <Form.Group as={Col} controlId="seriesDescription">
                                            <Label style={labelStyle}>Description</Label>
                                            <Form.Control value={this.state.series.data.description}
                                                          as="textarea" rows={1}
                                                          readOnly={true}
                                                          />
                                        </Form.Group>
                                    </Row>
                                    <Row className={"details-row"}>
                                        <Form.Group as={Col} controlId="seriesDivisionType">
                                            <Label style={labelStyle}>Division Type</Label>
                                            <FormSelect value={this.state.series.data.divisionType}
                                                        disabled={true}>
                                                {SeriesMetaData.DIVISION_TYPES.map(divisionType => (
                                                    <option>{divisionType}</option>
                                                ))
                                                }
                                            </FormSelect>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="seriesRecommendedAge">
                                            <Label style={labelStyle}>Recommended Age</Label>
                                            <Form.Control type="number" value={this.state.series.data.ageRecommended}
                                                          readOnly={true}
                                                          />
                                        </Form.Group>
                                    </Row>
                            </>
                            }
                            {/* SEASONS */}
                            {this.state.series.data.seasons.length > 0 &&
                            <>
                                <h5 style={labelGroupStyle}>Season Details</h5>
                                <Row className={"details-row"}>
                                    <Form.Group as={Col} controlId="seasonId">
                                        <Label style={labelStyle}>Season ID</Label>
                                        <Form.Control type="number" value={this.state.season.id}
                                                      readOnly={true}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="seasonName">
                                        <Label style={labelStyle}>Name</Label>
                                        <Form.Control value={this.state.season.name}
                                                      readOnly={true}
                                                      onChange={(e) => this.setState(prevState => ({
                                                          season: {
                                                              ...prevState.season,
                                                              name: e.target.value
                                                          }
                                                      }))}/>
                                    </Form.Group>
                                </Row>
                            </>
                            }

                            {/* EPISODES */}
                            {this.state.episodes.length > 0 &&
                            <>
                                <h5 style={labelGroupStyle}>Episodes assigned to Product</h5>
                                <Table responsive hover bordered striped>
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Episode Number</th>
                                        <th>Episode Title</th>
                                        <th>Sort Order in Season</th>
                                        <th>Manage</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.episodes.map(episode => (
                                        <tr role="row" key={episode.id}>
                                            <td>
                                                <Link to={"/" + GlobalConstants.APP_PATH + "series/" + this.state.series.data.id + "/seasons/" + this.state.season.id + "/episodes/" + episode.id} style={{fontStyle: "italic", color: "#333"}}>{episode.id}</Link>
                                            </td>
                                            <td>{episode.episodeNumber}</td>
                                            <td>{episode.episodeTitle}</td>
                                            <td>{episode.seriesIndex}</td>
                                            <td>
                                                <Button variant="info" onClick={() => {
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        episode: {...episode, showDialog: true}
                                                    }));
                                                }}><GrEdit/></Button>
                                                <span style={{margin: "5px"}}></span>

                                                <Button variant="danger" onClick={() => {
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        episode: episode,
                                                        deletionModals: {
                                                            ...prevState.deletionModals,
                                                            episode: true
                                                        }
                                                    }));
                                                }}><BsTrash/></Button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </>
                            }
                        </>
                        }
                        {this.state.product.id != null &&
                        <>
                            <hr/>
                            <h4 style={{cursor: "pointer"}} onClick={() => this.setState(prevState => ({
                                ...prevState,
                                sections: {...prevState.sections, productGroups: !this.state.sections.productGroups}
                            }))}><FaLayerGroup/>&#xA0;Product Groups
                                <span style={{float: "right", marginRight: "10px"}}
                                      onClick={() => this.setState(prevState => ({
                                          ...prevState,
                                          sections: {
                                              ...prevState.sections,
                                              productGroups: !this.state.sections.productGroups
                                          }
                                      }))}>{this.state.sections.productGroups ? <IoIosArrowDropup/> :
                                    <IoIosArrowDropdown/>}</span></h4>
                            <hr/>
                            {this.state.sections.productGroups &&
                            <>
                                <button type="button" className="form-btn-ci" style={{marginBottom: "15px"}}
                                        onClick={() => this.setState(prevState => ({
                                            ...prevState,
                                            productGroups: {...prevState.productGroups, showDialog: true}
                                        }))}>Add
                                </button>
                                <Table responsive striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Remove</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.productGroups.data.filter(group => this.state.product.productGroupIds.includes(group.id)).map(group => (
                                        <tr role="row" key={group.id}>
                                            <td>
                                                <a style={{color: "#333", fontStyle: "italic"}}
                                                   href={GlobalConstants.SPINE_CLIENT_HOST + GlobalConstants.APP_PATH_PRODUCTGROUP_DETAILS + group.id}>{group.id}</a>
                                            </td>
                                            <td>{group.name}</td>
                                            <td>
                                                <Button variant="danger" onClick={() => {
                                                    this.selectedProductGroup = group;
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        deletionModals: {
                                                            ...prevState.deletionModals,
                                                            productGroup: true
                                                        }
                                                    }));
                                                }}><BsTrash/></Button>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                </Table>
                            </>
                            }
                        </>
                        }
                        <hr/>
                        <h4 style={{cursor: "pointer"}} onClick={() => this.setState(prevState => ({
                            ...prevState,
                            sections: {...prevState.sections, technicalDetails: !this.state.sections.technicalDetails}
                        }))}><GoSettings/>&#xA0;Technical Details
                            <span style={{float: "right", marginRight: "10px"}}
                                  onClick={() => this.setState(prevState => ({
                                      ...prevState,
                                      sections: {
                                          ...prevState.sections,
                                          technicalDetails: !this.state.sections.technicalDetails
                                      }
                                  }))}>{this.state.sections.technicalDetails ? <IoIosArrowDropup/> :
                                <IoIosArrowDropdown/>}</span></h4>
                        <hr/>
                        {this.state.sections.technicalDetails &&
                        <>
                            <Row className={"details-row"}>
                                <Form.Group as={Col} controlId="productIapProductIdentifier">
                                    <Label style={labelStyle}>IAP Product Identifier</Label>
                                    <InputGroup>
                                        <Form.Control value={this.state.product.iapProductIdentifier}
                                                      readOnly={!this.state.editMode.active}
                                                      disabled={this.state.product.state !== 'NEW'}
                                                      onBlur={() => this.iapIsValid()}
                                                      onChange={(e) => this.setState(prevState => ({
                                                          product: {
                                                              ...prevState.product,
                                                              iapProductIdentifier: e.target.value
                                                          }
                                                      }))}/>
                                        {this.state.editMode.active && this.state.product.productType === 'SUBSCRIPTION' &&
                                            <Button variant="outline-secondary"
                                                    onClick={() => this.setState(prevState => ({
                                                        ...prevState,
                                                        showValidIapsDialog: true
                                                    }))}>Pick</Button>
                                        }
                                    </InputGroup>

                                </Form.Group>
                                <Form.Group as={Col} controlId="productExternalId">
                                    <Label style={labelStyle}>External ID</Label>
                                    <Form.Control value={this.state.product.externalId}
                                                  readOnly={!this.state.editMode.active}
                                                  onChange={(e) => this.setState(prevState => ({
                                                      product: {
                                                          ...prevState.product,
                                                          externalId: e.target.value
                                                      }
                                                  }))}/>
                                </Form.Group>
                            </Row>
                            {this.state.editMode.expertMode &&
                            <>
                                <Row className={"details-row"}>
                                    <Form.Group as={Col} controlId="productRequiredReaderVersion">
                                        <Label style={labelStyle}>Required Reader Version</Label>
                                        <Form.Control value={this.state.product.requiredReaderVersion}
                                                      readOnly={!this.state.editMode.active}
                                                      onChange={(e) => this.setState(prevState => ({
                                                          product: {
                                                              ...prevState.product,
                                                              requiredReaderVersion: e.target.value
                                                          }
                                                      }))}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="productVariantId">
                                        <Label style={labelStyle}>Variant ID</Label>
                                        <Form.Control value={this.state.product.variantId}
                                                      readOnly={!this.state.editMode.active}
                                                      onChange={(e) => this.setState(prevState => ({
                                                          product: {
                                                              ...prevState.product,
                                                              variantId: e.target.value
                                                          }
                                                      }))}/>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} controlId="productSeoText">
                                        <Label style={labelStyle}>SEO Text</Label>
                                        <Form.Control as="textarea" col={2} value={this.state.product.seoText}
                                                      readOnly={!this.state.editMode.active}
                                                      onChange={(e) => this.setState(prevState => ({
                                                          product: {
                                                              ...prevState.product,
                                                              seoText: e.target.value
                                                          }
                                                      }))}/>
                                    </Form.Group>
                                </Row>
                            </>
                            }
                            <Row className={"details-row"}>
                                <Form.Group as={Col} controlId="productPrices">
                                    <Label style={labelStyle}>Prices</Label>
                                    {this.state.editMode.active &&
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            placeholder="Country code, i.e. 'DE'"
                                            onChange={(e) => this.newPrice.countryCode = e.target.value}
                                        />
                                        <FormControl
                                            placeholder="Amount, i.e. '4.99'"
                                            onChange={(e) => this.newPrice.amount = e.target.value}
                                        />
                                        <FormControl
                                            placeholder="Currency code, i.e. 'EUR'"
                                            onChange={(e) => this.newPrice.currencyCode = e.target.value}
                                        />
                                            <Button variant="outline-secondary" onClick={() => {
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    product: {
                                                        ...prevState.product,
                                                        prices: this.state.product.prices.concat(this.newPrice)
                                                    }
                                                }));
                                            }}>Add</Button>
                                    </InputGroup>
                                    }
                                    {productPrices}
                                </Form.Group>
                                {this.state.editMode.expertMode &&
                                <Form.Group as={Col} controlId="productEans">
                                    <Label style={labelStyle}>EANs</Label>
                                    {this.state.editMode.active &&
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            placeholder="Add a new ean"
                                            onChange={(e) => this.newEan = e.target.value}
                                        />
                                            <Button variant="outline-secondary" onClick={() => {
                                                if (!this.state.product.eans.includes(this.newEan) && this.newEan !== "") {
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        product: {
                                                            ...prevState.product,
                                                            eans: this.state.product.eans.concat(this.newEan)
                                                        }
                                                    }));
                                                } else {
                                                    this.props.addToast("This ean already exists or is not valid.", {
                                                        autoDismiss: true,
                                                        appearance: 'warning'
                                                    });
                                                }
                                            }}>Add</Button>
                                    </InputGroup>
                                    }
                                    {productEans}
                                </Form.Group>
                                }
                            </Row>
                        </>
                        }
                        <hr/>
                        <h4 style={{cursor: "pointer"}} onClick={() => this.setState(prevState => ({
                            ...prevState,
                            sections: {...prevState.sections, appearance: !this.state.sections.appearance}
                        }))}><GrView/>&#xA0;Appearance
                            <span style={{float: "right", marginRight: "10px"}}
                                  onClick={() => this.setState(prevState => ({
                                      ...prevState,
                                      sections: {
                                          ...prevState.sections,
                                          appearance: !this.state.sections.appearance
                                      }
                                  }))}>{this.state.sections.appearance ? <IoIosArrowDropup/> :
                                <IoIosArrowDropdown/>}</span></h4>
                        <hr/>
                        {this.state.sections.appearance &&
                        <>
                            <Row className={"details-row"}>
                                <Form.Group as={Col} controlId="productAppearance">
                                    <Label style={labelStyle}>Appearance</Label>
                                    {this.state.editMode.expertMode &&
                                    <>
                                        <FormCheck id="excludeFromWebSearchSwitch" type="switch"
                                                   label="Exclude from Websearch"
                                                   checked={this.state.product.excludeFromSearch}
                                                   onChange={() => this.setState(prevState => ({
                                                       product: {
                                                           ...prevState.product,
                                                           excludeFromSearch: !this.state.product.excludeFromSearch
                                                       }
                                                   }))}
                                                   readOnly={!this.state.editMode.active}/>
                                        <FormCheck id="defaultPreviewSwitch" type="switch" label="Default Preview"
                                                   checked={this.state.product.defaultPreview}
                                                   onChange={() => this.setState(prevState => ({
                                                       product: {
                                                           ...prevState.product,
                                                           defaultPreview: !this.state.product.defaultPreview
                                                       }
                                                   }))}
                                                   readOnly={!this.state.editMode.active}/>
                                    </>
                                    }
                                    <Form.Switch id="freemiumSwitch" label="Freemium"
                                               checked={this.state.product.freemium}
                                               onChange={() => this.setState(prevState => ({
                                                   product: {
                                                       ...prevState.product,
                                                       freemium: !this.state.product.freemium
                                                   }
                                               }))}
                                               disabled={!this.state.editMode.active}/>
                                </Form.Group>
                            </Row>
                            {this.state.editMode.expertMode &&
                            <Row className={"details-row"}>
                                <Form.Group as={Col} controlId="productVideoUrl">
                                    <Label style={labelStyle}>Video URL</Label>
                                    <Form.Control value={this.state.product.videoUrl}
                                                  onChange={(e) => this.setState(prevState => ({
                                                      product: {
                                                          ...prevState.product,
                                                          videoUrl: e.target.value
                                                      }
                                                  }))}
                                                  readOnly={!this.state.editMode.active}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="productTranslator">
                                    <Label style={labelStyle}>Translator</Label>
                                    <Form.Control value={this.state.product.translator}
                                                  onChange={(e) => this.setState(prevState => ({
                                                      product: {
                                                          ...prevState.product,
                                                          translator: e.target.value
                                                      }
                                                  }))}
                                                  readOnly={!this.state.editMode.active}/>
                                </Form.Group>
                            </Row>
                            }
                        </>
                        }
                        <hr/>
                        <h4 style={{cursor: "pointer"}} onClick={() => this.setState(prevState => ({
                            ...prevState,
                            sections: {...prevState.sections, reporting: !this.state.sections.reporting}
                        }))}><HiOutlineDocumentReport/>&#xA0;Reporting
                            <span style={{float: "right", marginRight: "10px", cursor: "pointer"}}
                                  onClick={() => this.setState(prevState => ({
                                      ...prevState,
                                      sections: {
                                          ...prevState.sections,
                                          reporting: !this.state.sections.reporting
                                      }
                                  }))}>{this.state.sections.reporting ? <IoIosArrowDropup/> :
                                <IoIosArrowDropdown/>}</span></h4>
                        <hr/>
                        {this.state.sections.reporting &&
                        <>
                            <button className={this.state.id != null ? "form-btn-ci-blue" : "form-btn-ci-off"}
                                    style={{marginBottom: "10px"}}
                                    disabled={this.state.id == null}
                                    type="button"
                                    onClick={() => this.setState(prevState => ({
                                        ...prevState,
                                        showReportingProductDialog: true
                                    }))}>Change Reporting Data
                            </button>
                            <Row className={"details-row"}>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>External ID</Label>
                                    <Form.Control type="text" value={this.state.reportingProduct.externalId}
                                                  readOnly={true}/>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Created By</Label>
                                    <Form.Control type="text" value={this.state.reportingProduct.createdBy}
                                                  readOnly={true}/>
                                </Form.Group>
                            </Row>
                            <Row className={"details-row"}>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Aggregator ID</Label>
                                    <Form.Control type="text" value={this.state.aggregator.id}
                                                  readOnly={true}/>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Aggregator Name</Label>
                                    <Form.Control type="text" value={this.state.aggregator.name}
                                                  readOnly={true}/>
                                </Form.Group>
                            </Row>
                        </>
                        }
                        <hr/>
                        <h4 style={{cursor: "pointer"}} onClick={() => this.setState(prevState => ({
                            ...prevState,
                            sections: {...prevState.sections, variousAttributes: !this.state.sections.variousAttributes}
                        }))}><FaReact/>&#xA0;Various Attributes
                            <span style={{float: "right", marginRight: "10px", cursor: "pointer"}}
                                  onClick={() => this.setState(prevState => ({
                                      ...prevState,
                                      sections: {
                                          ...prevState.sections,
                                          variousAttributes: !this.state.sections.variousAttributes
                                      }
                                  }))}>{this.state.sections.variousAttributes ? <IoIosArrowDropup/> :
                                <IoIosArrowDropdown/>}</span></h4>
                        <hr/>
                        {this.state.sections.variousAttributes &&
                        <Row className={"details-row"}>
                            {this.state.product.attributes !== null &&
                            <Table>
                                <thead>
                                <tr>
                                    <th>Attribute</th>
                                    <th>Value</th>
                                    {this.state.editMode.active &&
                                    <th>Maintain</th>
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {Object.keys(this.state.product.attributes).map((key, i) => (
                                    <tr role="row" key={key}>
                                        {this.state.selectedAttribute === key ?
                                            <>
                                                <td>{key}</td>
                                                <td>
                                                    <Form.Control value={this.state.product.attributes[key]}
                                                                  onChange={(e) => this.setState(prevState => ({
                                                                      ...prevState,
                                                                      product: {
                                                                          ...prevState.product,
                                                                          attributes: {
                                                                              ...prevState.product.attributes,
                                                                              [key]: e.target.value
                                                                          }
                                                                      }
                                                                  }))}/>
                                                </td>
                                                <td>
                                                    <Button variant="success"
                                                            onClick={() => this.setState(prevState => ({
                                                                ...prevState,
                                                                selectedAttribute: null
                                                            }))}><MdDone/></Button>
                                                </td>
                                            </>
                                            :
                                            <>
                                                <td>{key}</td>
                                                <td>{this.state.product.attributes[key]}</td>
                                                {this.state.editMode.active &&
                                                <td>
                                                    <Button variant="info"
                                                            onClick={() => this.setState(prevState => ({
                                                                ...prevState,
                                                                selectedAttribute: key
                                                            }))}><GrEdit/></Button>
                                                </td>
                                                }
                                            </>
                                        }
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                            }
                        </Row>
                        }
                    </Form>
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "products", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateProduct()}/>
                    }
                </div>

                <InfoModal show={this.state.categories.showDialog}
                           onHide={() => {
                               this.addCategoriesMode = false;
                               this.setState(prevState => ({
                                   ...prevState,
                                   categories: {
                                       ...prevState.categories,
                                       showDialog: false,
                                       filter: {
                                           query: "",
                                       },
                                   },
                               }))
                           }}
                           title="Add categories to the product"
                           body={this.addCategoryDialog()}/>

                <InfoModal show={this.state.tags.showDialog}
                           onHide={() => this.setState(prevState => ({
                               ...prevState,
                               tags: {
                                   ...prevState.tags,
                                   showDialog: false,
                               }
                           }))}
                           title="Add tags to the product"
                           body={<PickTagDialog
                                   onClickTag={(tag) => {
                                       let tagIds = [];
                                       this.state.product.tags.forEach(tg => (tagIds.push(tg.id)));
                                       if (!tagIds.includes(tag.id)) {
                                           this.setState(prevState => ({
                                               ...prevState,
                                               product: {
                                                   ...prevState.product,
                                                   tags: this.state.product.tags.concat(tag)
                                               },
                                               tags: {
                                                   ...prevState.tags,
                                                   showDialog: false,
                                               }
                                           }));
                                       } else {
                                           this.props.addToast("This tag is already assigned to this product", {
                                               autoDismiss: true,
                                               appearance: 'warning'
                                           });
                                       }
                                   }}
                           />}/>

                <InfoModal show={this.state.imprints.showDialog}
                           onHide={() => this.setState(prevState => ({
                               ...prevState,
                               imprints: {
                                   ...prevState.imprints,
                                   showDialog: false,
                                   filter: {
                                       name: "",
                                   }
                               }
                           }))}
                           title="Pick an imprint"
                           body={this.pickImprintDialog()}/>

                <ActionModal show={this.state.deletionModals.productGroup}
                             onHide={() => this.setState((prevState) => ({
                                 ...prevState,
                                 deletionModals: {...prevState.deletionModals, productGroup: false}
                             }))}
                             onAction={() => this.removeFromProductGroup()}
                             actionButtonText="Remove"
                             title={"Remove from product group"}
                             body={"Are you sure you want to remove this product from the selected product group?"}/>

                <TableModal show={this.state.productGroups.showDialog}
                            onHide={() => this.setState(prevState => ({
                                ...prevState,
                                productGroups: {
                                    ...prevState.productGroups,
                                    showDialog: false
                                }
                            }))}
                            onAction={() => this.addToProductGroups()}
                            title={"Add product to product groups"}
                            tableHeader={[{label: "ID", id: "id"}, {label: "Name", id: "name"}]}
                            tableData={this.state.productGroups.data.filter(group => !this.state.product.productGroupIds.includes(group.id))}
                            onToggle={(productGroup) => this.toggleProductGroup(productGroup)}
                            selectable={true}/>

                <ActionModal show={this.state.deletionModals.assetProduct}
                             onHide={() => this.setState((prevState) => ({
                                 ...prevState,
                                 deletionModals: {...prevState.deletionModals, assetProduct: false}
                             }))}
                             onAction={() => this.removeAssetProduct()}
                             actionButtonText="Remove"
                             title={"Remove the Asset Product"}
                             body={"Are you sure you want to remove this Asset Product from the selected product?"}/>

                <InfoModal show={this.state.showValidIapsDialog}
                           onHide={() => this.setState(prevState => ({...prevState, showValidIapsDialog: false}))}
                           title="Select a valid IAP Product Identifier"
                           body={this.pickIapDialog()}/>

                <ActionModal show={this.state.assetProducts.showDialog}
                             onHide={() => this.setState((prevState) => ({
                                 ...prevState,
                                 assetProducts: {...prevState.assetProducts, showDialog: false}
                             }))}
                             onAction={() => this.saveOrUpdateAssetAndAssetProduct()}
                             actionButtonText="Save"
                             title={this.selectedAssetProduct.id != null ? "Edit Asset Product " + this.selectedAssetProduct.id : "Add an Asset Product"}
                             body={<AddOrEditAssetProductDialog
                                 assetProduct={this.selectedAssetProduct}
                                 asset={this.selectedAsset}
                                 onUpdateAsset={(asset) => this.selectedAsset = asset}
                                 onUpdateAssetProduct={(assetProduct) => this.selectedAssetProduct = assetProduct}/>}/>

                <ActionModal show={this.state.series.showDialog}
                             onHide={() => this.setState((prevState) => ({
                                 ...prevState,
                                 series: {...prevState.series, showDialog: false}
                             }))}
                             onAction={() => this.setState((prevState) => ({
                                 ...prevState,
                                 series: {...prevState.series, showDialog: false}
                             }))}
                             actionButtonText="Finish"
                             title={this.state.series.data.id != null ? "Edit Series " + this.state.series.data.id : "Add Product " + this.state.id + " to a series"}
                             body={<AddProductToSeriesDialog
                                 series={this.state.series.data}
                                 products={[this.state.product]}/>}/>

                <ActionModal show={this.state.deletionModals.episode || this.state.deletionModals.allEpisodes}
                             onHide={() => this.setState((prevState) => ({
                                 ...prevState,
                                 deletionModals: {...prevState.deletionModals, episode: false, allEpisodes: false}
                             }))}
                             onAction={() => {
                                 if (this.state.deletionModals.episode) {
                                     this.deleteEpisodes([this.state.episode]).then();
                                 } else {
                                     this.deleteEpisodes(this.state.episodes).then();
                                 }
                             }}
                             actionButtonText="Remove"
                             title={"Delete episode(s)"}
                             body={this.state.deletionModals.episode ?
                                 "Are you sure you want to delete episode " + this.state.episode.id + "?" :
                                 "Do you want to remove this product from its series? This will delete all episodes of this product"}/>

                <ActionModal show={this.state.episode.showDialog}
                             onHide={() => this.setState(prevState => ({
                                 ...prevState,
                                 episode: {...prevState.episode, showDialog: false}
                             }))}
                             onAction={() => this.updateEpisode()}
                             actionButtonText="Save"
                             title={"Update episode"}
                             body={this.updateEpisodeDialog()}/>

                <InfoModal show={this.state.showReportingProductDialog}
                           onHide={() => this.setState(prevState => ({
                               ...prevState,
                               showReportingProductDialog: false
                           }))}
                           title="Edit the Reporting Product"
                           body={<EditReportingProductDialog
                               productId={this.state.product.id}
                               reportingProduct={this.state.reportingProduct}
                               aggregator={this.state.aggregator}
                               onSaveSuccess={async () => {
                                   this.props.addToast("Aggregator has been set successfully", {
                                       autoDismiss: true,
                                       appearance: "success"
                                   });
                                   await this.loadReportingProduct();
                                   this.setState(prevState => ({
                                       ...prevState,
                                       showReportingProductDialog: false
                                   }));
                               }}
                               onSaveError={(error) => {
                                   this.setState(prevState => ({...prevState, error: error}));
                               }}
                               onUpdateReportingProduct={(reportingProduct) => this.saveOrUpdateReportingProduct(reportingProduct)}
                           />}/>

                <InfoModal show={this.state.showLanguageDialog}
                           onHide={() => this.setState(prevState => ({...prevState, showLanguageDialog: false}))}
                           title="Pick a language"
                           body={this.pickLanguageDialog()}
                />

                <InfoModal show={this.state.pickAssetDialog}
                           onHide={() => this.setState(prevState => ({...prevState, pickAssetDialog: false}))}
                           title="Select an existing asset"
                           body={<PickAssetDialog
                               assets={this.state.assets}
                               productId={this.state.product.id}
                               onCreateAssetProduct={(ap) => {
                                   this.selectedAssetProduct = ap;
                                   this.selectedAsset = {id: ap.assetId};
                                   this.saveOrUpdateAssetAndAssetProduct().then(r => r);
                               }}
                           />
                           }
                />

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))}
                />
            </>
        );
    }

    //Helpers
    recommendationIsUpdated() {
        let isUpdated = false;
        let recommendationNew = this.state.recommendationNew;
        let product = this.state.product;
        if(recommendationNew.id == null) {
            return false;
        }

        if(recommendationNew.title !== product.title) {
            recommendationNew.title = product.title;
            isUpdated = true;
        }

        if(recommendationNew.state !== product.state) {
            recommendationNew.state = product.state;
            isUpdated = true;
        }

        if(recommendationNew.coverUrl !== product.coverUrl) {
            recommendationNew.cover = product.coverUrl;
            isUpdated = true;
        }

        if(recommendationNew.ageMin !== product.ageMin) {
            recommendationNew.ageMin = product.ageMin;
            isUpdated = true;
        }

        if(recommendationNew.ageMax !== product.ageMax) {
            recommendationNew.ageMax = product.ageMax;
            isUpdated = true;
        }

        if(recommendationNew.publicationDate !== new Date(product.publicationDate).toISOString()) {
            recommendationNew.publicationDate = product.publicationDate != null ? new Date(product.publicationDate).toISOString() : null;
            isUpdated = true;
        }

        if(isUpdated) {
            this.setState(prevState => ({...prevState, recommendationNew: recommendationNew}));
        }

        return isUpdated;
    }

    //API Methods
    async updateProductState() {

        if (this.state.product.id == null) {
            this.props.addToast("The product must be created first before its state can be changed", {
                autoDismiss: true,
                appearance: "warning"
            });
        } else if (this.state.changeProductStateTo === "") {
            this.props.addToast("Please select a product state the products should be changed to.", {
                autoDismiss: true,
                appearance: 'warning'
            });
        } else {
            let result = await UpdateProductState(this.state.product, this.state.changeProductStateTo);

            if (!result.error) {
                //Update the recommendation if required
                if (this.state.recommendationNew.id != null) {
                    let recommendationNew = this.state.recommendationNew;
                    recommendationNew.state = this.state.changeProductStateTo;
                    this.setState(prevState => ({...prevState, recommendationNew: recommendationNew}));
                    let res = await UpdateRecommendationProduct(recommendationNew);
                    if (res.error) {
                        this.setState(prevState => ({...prevState, error: res}));
                    }
                }
                await this.loadProduct()
            } else {
                this.setState(prevState => ({...prevState, error: result}));
            }
        }
    }

    async loadReportingProduct() {
        let reportingProduct = await GetReportingProduct(this.state.id);
        if(!reportingProduct.error) {
            let result = reportingProduct.result;
            if(result != null) {
                let aggregator = {};
                if(result["_embedded"] != null && result["_embedded"]["aggregator"] != null) {
                    aggregator = result["_embedded"]["aggregator"];
                }
                this.setState(prevState => ({...prevState, reportingProduct: result, aggregator: aggregator}));
            }
        }
    }

    async saveOrUpdateReportingProduct(reportingProduct) {

        if (reportingProduct.externalId == null) {
            reportingProduct.externalId = "unknown";
        }

        let updatedReportingProduct = await UpdateReportingProduct(this.state.id, reportingProduct);

        if (!updatedReportingProduct.error) {
            this.setState(prevState => ({
                ...prevState,
                reportingProduct: updatedReportingProduct.result,
                showReportingProductDialog: false
            }));
        } else {
            this.setState(prevState => ({...prevState, error: updatedReportingProduct}));
        }
    }

    async saveOrUpdateProduct() {

        if (!RequiredFieldsAreValid('product', [ProductMetaData.DETAILS_GENERAL, ProductMetaData.DETAILS_CONTENT], this.state, (s) => this.setState(s))) {
            return;
        } else if (this.state.product.imprintId == null) {
            let validationErrors = this.state.validationErrors;
            validationErrors.push("imprintId")
            this.setState(prevState => ({...prevState, validationErrors: validationErrors}));
            return;
        }

        if(!this.iapIsValid()) {
            return;
        }

        let product = {};
        if (this.state.product.id != null) {
            product = await UpdateProduct(this.state.product);

            //Updated recommendation if required
            if(!product.error && this.recommendationIsUpdated()) {
                let rec = await UpdateRecommendationProduct(this.state.recommendationNew);
                if(rec.error) {
                    this.setState(prevState => ({...prevState, error: rec}));
                }
            }
        } else {
            product = await CreateProduct(this.state.product);
        }

        if (!product.error) {
            if (this.state.product.id == null) {
                this.props.history.push("/"+ GlobalConstants.APP_PATH + "products/" + product.result.id);
            }
            this.setState((prevState) => ({
                ...prevState,
                product: product.result,
                editMode: {...prevState.editMode, active: this.state.editMode.autoSave}
            }));

            this.props.addToast("The product has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });

            //Reload the product
            await this.loadProduct();
        } else {
            this.setState(prevState => ({...prevState, error: product}));
        }
    }

    async loadCategoryPage(page) {
        const loadedCategories = await GetFilteredCategories({query: this.state.categories.filter.query, page: page});

        if (loadedCategories.error === true) {
            this.setState(prevState => ({...prevState, error: loadedCategories}));
        }
        this.setState(prevState => ({
            ...prevState,
            categories: {
                ...prevState.categories,
                data: loadedCategories.result,
                lastPage: Math.ceil(loadedCategories.length / 20),
                filter: {
                    ...prevState.filter,
                    page: page,
                }
            }
        }));
    }

    async loadImprintsPage(page) {
        const loadedImprints = await GetFilteredImprints({page: page, name: this.state.imprints.filter.name});

        if (loadedImprints.error === true) {
            this.setState(prevState => ({...prevState, error: loadedImprints}));
        }
        this.setState(prevState => ({
            ...prevState,
            imprints: {
                ...prevState.imprints,
                data: loadedImprints.result,
                filter: {
                    ...prevState.imprints.filter,
                    page: page
                }
            }
        }));
    }

    async removeFromProductGroup() {
        let result = await RemoveProductFromProductGroup(this.state.product, this.selectedProductGroup);
        if (result.error) {
            this.setState(prevState => ({...prevState, error: result}));
        } else {
            this.setState(prevState => ({
                ...prevState,
                product: {
                    ...prevState.product,
                    productGroupIds: this.state.product.productGroupIds.filter(id => id !== this.selectedProductGroup.id)
                },
                deletionModals: {
                    ...prevState.deletionModals,
                    productGroup: false
                }
            }))
        }
    }

    async addToProductGroups() {
        const selectedProductGroups = this.state.productGroups.data.filter(productGroup => productGroup.isChecked === true);

        for (const productGroup of selectedProductGroups) {
            let result = await AddProductToProductGroup(this.state.product, productGroup);

            if (result.error) {
                this.setState(prevState => ({...prevState, error: result}));
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    product: {
                        ...prevState.product,
                        productGroupIds: this.state.product.productGroupIds.concat(productGroup.id)
                    }
                }))
            }
        }

        this.setState(prevState => ({...prevState, productGroups: {...prevState.productGroups, showDialog: false}}));
    }

    async removeAssetProduct() {
        //Find the matching asset product
        let assetProduct = this.state.product.assetProducts.filter(aP => aP.assetId === this.selectedAsset.id)[0];
        if (assetProduct != null) {
            let result = await DeleteAssetProduct(assetProduct);
            if (result.error) {
                this.setState(prevState => ({...prevState, error: result}));
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    product: {
                        ...prevState.product,
                        assets: this.state.product.assets.filter(asset => asset.id !== this.selectedAsset.id)
                    },
                    deletionModals: {
                        ...prevState.deletionModals,
                        assetProduct: false
                    }
                }))
            }
        }
    }

    async saveOrUpdateAssetAndAssetProduct() {
        //Asset
        if (this.selectedAsset.id == null) {
            //Create asset
            let uploadedAsset = await UploadAsset(this.selectedAsset);

            if (uploadedAsset.error) {
                this.setState(prevState => ({...prevState, error: uploadedAsset}));
                return;
            } else {
                this.selectedAsset = uploadedAsset.result;
            }
        }

        //Asset Product
        let updatedAssetProduct = {}
        if (this.selectedAssetProduct.id == null) {
            //Create it
            updatedAssetProduct = await CreateAssetProduct(this.selectedAssetProduct);
        } else {
            //Update it
            updatedAssetProduct = await UpdateAssetProduct(this.selectedAssetProduct);
        }

        if (updatedAssetProduct.error) {
            this.setState(prevState => ({...prevState, error: updatedAssetProduct}));
        } else {
            this.loadProduct().then(r => this.setState(prevState => ({
                ...prevState,
                assetProducts: {
                    ...prevState.assetProducts,
                    showDialog: false,
                },
                pickAssetDialog: false
            })));
        }
    }

    async updateEpisode() {
        UpdateEpisode(this.state.episode)
            .then(r => {
                if(!r.error) {
                    this.loadProduct().then(l => {
                        this.setState(prevState => ({
                            ...prevState,
                            episode: {
                                ...prevState.episode,
                                showDialog: false
                            }
                        }));
                        this.props.addToast("Episode " + this.state.episode.id + " has been updated successfully.", {
                            autoDismiss: true,
                            appearance: 'success'
                        });
                    });
                } else {
                    this.setState(prevState => ({...prevState, error: r}));
                }
            })
    }

    async deleteEpisode() {
        DeleteEpisode(this.state.series.data.id, this.state.season.id, this.state.episode)
            .then(r => {
                if(!r.error){
                    this.loadProduct().then(l => {
                        this.setState(prevState => ({
                            ...prevState,
                            deletionModals: {
                                ...prevState.deletionModals,
                                episode: false
                            }
                        }));
                    });
                } else {
                    this.setState(prevState => ({...prevState, error: r}));
                }
            });
    }

    async deleteEpisodes(episodes) {
        let i = 0;
        let errors = [];
        for(const episode of episodes) {
            let result = await DeleteEpisode(this.state.series.data.id, this.state.season.id, episode);

            //Check if there is an error
            if(result.error) {
                errors.push(result)
            }
            i += 1;

            //Reload the product if this was the last episode to be deleted
            if(i >= episodes.length) {
                this.loadProduct().then(l => {
                    this.setState(prevState => ({
                        ...prevState,
                        deletionModals: {
                            ...prevState.deletionModals,
                            episode: false,
                            allEpisodes: false
                        }
                    }));
                });
            }
        }

        if (errors.length > 0) {
            this.setState(prevState => ({...prevState, error: errors[0]}));
        }
    }

    //Selection
    toggleProductGroup(productGroup) {
        let productGroups = this.state.productGroups.data;
        const index = productGroups.indexOf(productGroup);
        let group = productGroups[index];
        group.isChecked = !group.isChecked;
        productGroups[index] = group;
        this.setState(prevState => ({...prevState, productGroups: {...prevState.productGroups, data: productGroups}}));
    }

    //Validation
    iapIsValid() {
        //ensures that the iap of subscriptions has a defined structure, otherwise problems in reporting are possible
        let iapIsValid = false;
        if(this.state.product.productType === 'SUBSCRIPTION' && this.state.product.iapProductIdentifier != null && this.state.product.iapProductIdentifier !== "") {
            //Split the iap iapProductIdentifier into an array
            let arr = this.state.product.iapProductIdentifier.split(".");
            if(arr.length >= 3) {
                const appName = arr[0];
                const type = arr[1];
                const duration = arr[2];

                //Tigertones
                if(appName === 'tigertones') {
                    if(type === 'subscription' && ProductMetaData.ALLOWED_TIGERTONES_SUBSCRIPTION_DURATIONS.includes(duration)) {
                        iapIsValid = true;
                    }
                    if(type === 'tigerticket' && ProductMetaData.ALLOWED_TIGERTONES_TIGERTICKET_DURATIONS.includes(duration)) {
                        iapIsValid = true;
                    }
                }

                //Tigerbook
                if(appName === 'tigerbook') {
                    if(type === 'subscription' && ProductMetaData.ALLOWED_TIGERBOOK_SUBSCRIPTION_DURATIONS.includes(duration)) {
                        iapIsValid = true;
                    }
                }

                //Tigerbooks
                if(appName === 'tigerbooks') {
                    if(type === 'subscription' && ProductMetaData.ALLOWED_TIGERBOOKS_SUBSCRIPTION_DURATIONS.includes(duration)) {
                        iapIsValid = true;
                    }
                }
            }
        } else {
            iapIsValid = true;
        }

        if(!iapIsValid) {
            this.props.addToast("IAP Product Identifier must have the following pattern for product type 'subscription': '[APP_NAME].[TICKET_OR_SUBSCRIPTION].[DURATION]'." +
                "Hover over the 'Info' icon on top of the 'IAP Product Identifier' field to get some examples.", {
                autoDismiss: true,
                appearance: 'error'
            });
        }
        return iapIsValid;
    }

    //Dialogs
    addCategoryDialog() {
        return (
            <div>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    this.loadCategoryPage(1).then(r => r);
                }}>
                    <div className="row">
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Search for a category"
                                onChange={(e) => this.setState(prevState => ({
                                    ...prevState,
                                    categories: {
                                        ...prevState.categories,
                                        filter: {
                                            page: this.state.categories.filter.page,
                                            query: e.target.value,
                                        }
                                    }
                                }))}
                            />
                                <Button variant="outline-secondary"
                                        onClick={() => this.loadCategoryPage(1)}>Search</Button>
                        </InputGroup>
                    </div>
                </form>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Code</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.categories.data.map(category => (
                        <tr role="row" key={category.id} style={{cursor: "pointer"}} onClick={() => {
                            if (this.addCategoriesMode) {
                                this.addCategoryToProductCategories(category);
                            } else {
                                this.setSimilarityCategory(category);
                            }
                        }}>
                            <td>{category.id}</td>
                            <td>{category.name}</td>
                            <td>{category.code}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <Pagination>
                    <Pagination.Item hidden={this.state.categories.filter.page === 1}
                                     onClick={() => this.loadCategoryPage(1)}>&lt;&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.categories.filter.page === 1}
                                     onClick={() => this.loadCategoryPage(this.state.categories.filter.page - 1)}>&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.categories.filter.page <= 2}
                                     onClick={() => this.loadCategoryPage(this.state.categories.filter.page - 2)}>{this.state.categories.filter.page - 2}</Pagination.Item>
                    <Pagination.Item hidden={this.state.categories.filter.page === 1}
                                     onClick={() => this.loadCategoryPage(this.state.categories.filter.page - 1)}>{this.state.categories.filter.page - 1}</Pagination.Item>
                    <Pagination.Item active={true}>{this.state.categories.filter.page}</Pagination.Item>
                    <Pagination.Item hidden={this.state.categories.filter.page === this.state.categories.lastPage}
                        onClick={() => this.loadCategoryPage(this.state.categories.filter.page + 1)}>{this.state.categories.filter.page + 1}</Pagination.Item>
                    <Pagination.Item hidden={this.state.categories.filter.page > this.state.categories.lastPage - 2}
                        onClick={() => this.loadCategoryPage(this.state.categories.filter.page + 2)}>{this.state.categories.filter.page + 2}</Pagination.Item>
                    <Pagination.Item  hidden={this.state.categories.filter.page === this.state.categories.lastPage}
                        onClick={() => this.loadCategoryPage(this.state.categories.filter.page + 1)}>&gt;</Pagination.Item>
                </Pagination>
            </div>
        )
    }

    pickImprintDialog() {
        return (
            <div>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Search for an imprint"
                        onChange={(e) => this.setState(prevState => ({
                            ...prevState,
                            imprints: {
                                ...prevState.imprints,
                                filter: {
                                    page: this.state.imprints.filter.page,
                                    name: e.target.value,
                                }
                            }
                        }))}
                    />
                        <Button variant="outline-secondary"
                                onClick={() => this.loadImprintsPage(this.state.imprints.filter.page)}>Search</Button>
                </InputGroup>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Publishing House ID</th>
                        <th>Publishing House Name</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.imprints.data.map(imprint => (
                        <tr role="row" key={imprint.id} style={{cursor: "pointer"}} onClick={() => {
                            let validationErrors = this.state.validationErrors.filter(e => e !== "imprintId");
                            this.setState(prevState => ({
                                ...prevState,
                                product: {
                                    ...prevState.product,
                                    imprintId: imprint.id,
                                    imprint: imprint,
                                },
                                imprints: {
                                    ...prevState.imprints,
                                    showDialog: false
                                },
                                validationErrors: validationErrors
                            }));
                        }}>
                            <td>{imprint.id}</td>
                            <td>{imprint.name}</td>
                            <td>{imprint.publishingHouseId}</td>
                            <td>{imprint.publishingHouseName}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <Pagination>
                    <Pagination.Item hidden={this.state.imprints.filter.page === 1}
                                     onClick={() => this.loadImprintsPage(1)}>&lt;&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.imprints.filter.page === 1}
                                     onClick={() => this.loadImprintsPage(this.state.imprints.filter.page - 1)}>&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.imprints.filter.page <= 2}
                                     onClick={() => this.loadImprintsPage(this.state.imprints.filter.page - 2)}>{this.state.imprints.filter.page - 2}</Pagination.Item>
                    <Pagination.Item hidden={this.state.imprints.filter.page === 1}
                                     onClick={() => this.loadImprintsPage(this.state.imprints.filter.page - 1)}>{this.state.imprints.filter.page - 1}</Pagination.Item>
                    <Pagination.Item active={true}>{this.state.imprints.filter.page}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadImprintsPage(this.state.imprints.filter.page + 1)}>{this.state.imprints.filter.page + 1}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadImprintsPage(this.state.imprints.filter.page + 2)}>{this.state.imprints.filter.page + 2}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadImprintsPage(this.state.imprints.filter.page + 1)}>&gt;</Pagination.Item>
                </Pagination>
            </div>
        )
    }

    updateEpisodeDialog() {
        return(
            <>
                <Form>
                    <Row>
                        <Form.Group as={Col} controlId="episodeId">
                            <Label>ID</Label>
                            <Form.Control value={this.state.episode.id} readOnly />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="productId">
                            <Label>Product ID</Label>
                            <Form.Control value={this.state.episode.productId}
                                          readOnly={true} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="section">
                            <Label>Sort Order in Season</Label>
                            <Form.Control value={this.state.episode.seriesIndex}
                                          onChange={(e) => this.setState(prevState => ({
                                              ...prevState,
                                              episode: {
                                                  ...prevState.episode,
                                                  seriesIndex: e.target.value
                                              }
                                          }))} />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="episodeNumber">
                            <Label>Displayed Episode Number</Label>
                            <Form.Control value={this.state.episode.episodeNumber}
                                          onChange={(e) => this.setState(prevState => ({
                                              ...prevState,
                                              episode: {
                                                  ...prevState.episode,
                                                  episodeNumber: e.target.value
                                              }
                                          }))} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="episodeTitle">
                            <Label>Episode Title</Label>
                            <Form.Control value={this.state.episode.episodeTitle}
                                          onChange={(e) => this.setState(prevState => ({
                                              ...prevState,
                                              episode: {
                                                  ...prevState.episode,
                                                  episodeTitle: e.target.value
                                              }
                                          }))} />
                        </Form.Group>
                    </Row>
                </Form>
            </>
        );
    }

    pickIapDialog() {
        return (
          <Table bordered responsive striped hover>
              <thead>
                <tr>
                    <th>IAP Product Identifier</th>
                </tr>
              </thead>
              <tbody>
              {this.state.validIaps.map(iap => (
                  <tr role="row" key={iap} style={{cursor: "pointer"}}
                      onClick={() => this.setState(prevState => ({...prevState, product: {...prevState.product, iapProductIdentifier: iap}, showValidIapsDialog: false}))}>
                      <td>{iap}</td>
                  </tr>
              ))}
              </tbody>
          </Table>
        );
    }

    pickLanguageDialog() {
        return (
            <>
                <div style={{width: "100%"}}>
                    <h5>Most common:</h5>
                    <p style={{float: "left"}}>
                        <Button variant="outline-secondary" style={{marginRight: "10px"}} onClick={() => this.handleLanguageSelection('deu')}>German</Button>
                        <Button variant="outline-secondary" style={{marginRight: "10px"}} onClick={() => this.handleLanguageSelection('eng')}>English</Button>
                        <Button variant="outline-secondary" style={{marginRight: "10px"}} onClick={() => this.handleLanguageSelection('fra')}>French</Button>
                        <Button variant="outline-secondary" style={{marginRight: "10px"}} onClick={() => this.handleLanguageSelection('spa')}>Spanish</Button>
                        <Button variant="outline-secondary" style={{marginRight: "10px"}} onClick={() => this.handleLanguageSelection('ita')}>Italian</Button>
                        <Button variant="outline-secondary" style={{marginRight: "10px"}} onClick={() => this.handleLanguageSelection('rus')}>Russian</Button>
                        <Button variant="outline-secondary" style={{marginRight: "10px"}} onClick={() => this.handleLanguageSelection('fin')}>Finnish</Button>
                        <Button variant="outline-secondary" style={{marginRight: "10px"}} onClick={() => this.handleLanguageSelection('por')}>Portugues</Button>
                        <Button variant="outline-secondary" style={{marginRight: "10px"}} onClick={() => this.handleLanguageSelection('gsw')}>Swiss german</Button>
                    </p>
                    <br />
                    <br />
                </div>
                <hr />
                <Form>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Control type="text" placeholder="Search for a language"
                                          onChange={(e) => this.setState(prevState => ({...prevState, filteredIsos: this.state.isos.filter(i => i.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)}))} />
                        </Form.Group>
                    </Row>
                </Form>
                <Table bordered responsive hover striped>
                    <thead>
                        <tr>
                            <th>Language</th>
                            <th>ISO Code</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.filteredIsos.map(i => (
                        <tr role="row" style={{cursor: "pointer"}}
                            onClick={() => this.handleLanguageSelection(i.iso6392T ? i.iso6392T : i.iso6392B)}>
                            <td>{i.name}</td>
                            <td>{i.iso6392T ? i.iso6392T : i.iso6392B}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>
        );
    }

    handleLanguageSelection(lang) {
        this.setState(prevState => ({
            ...prevState,
            product: {
                ...prevState.product,
                language: lang.toLowerCase()
            },
            showLanguageDialog: false
        }));
    }

    //Handler
    addCategoryToProductCategories(category) {
        let categoryIds = [];
        this.state.product.categories.forEach(cat => (categoryIds.push(cat.id)));
        if (!categoryIds.includes(category.id)) {
            this.setState(prevState => ({
                ...prevState,
                product: {
                    ...prevState.product,
                    categories: this.state.product.categories.concat(category)
                }
            }));
        } else {
            this.props.addToast("This category is already assigned to this product", {
                autoDismiss: true,
                appearance: 'warning'
            });
        }
    }

    setSimilarityCategory(category) {
        this.setState(prevState => ({
            ...prevState,
            product: {
                ...prevState.product,
                similarityCategory: category,
            },
            categories: {...prevState.categories, showDialog: false}
        }));
    }

    recommendationAllowed(){
        if(this.state.product.id == null) {
            return false;
        }
        if (this.state.product.state !== "ACTIVE" && this.state.product.state !== "NEW") {
            return false;
        }
        return true;
    }

}

export default withToast(ProductDetails);