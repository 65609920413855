const PRODUCT_STATES = ['NEW', 'ACTIVE', 'INACTIVE', 'TAKEDOWN'];
const PRODUCT_TYPES = ['TIGERBOOK', 'MOVIE', 'AUDIOBOOK', 'BOOK', 'SUBSCRIPTION'];
const PRODUCT_CLASSIFICATIONS = ['TIGERBOOK', 'TIGERBOOKDISCOUNT', 'MOVIE', 'EBOOK', 'RADIOPLAY', 'AUDIOBOOK', 'MUSIC', 'SUBSCRIPTION'];
const ALLOWED_TIGERTONES_SUBSCRIPTION_DURATIONS = ['14days', '1month', '6weeks', '4month', '6month', '12month', '2years'];
const ALLOWED_TIGERTONES_TIGERTICKET_DURATIONS = ['14days', '1month', '6weeks', '2month', '3month', '6month', '12month', '24month', 'coupon'];
const ALLOWED_TIGERBOOK_SUBSCRIPTION_DURATIONS = ['1day', '2days', '3days', '4days', '5days', '6days', '1week', '14days', '2weeks', '3weeks', '1month'];
const ALLOWED_TIGERBOOKS_SUBSCRIPTION_DURATIONS = ['1week', '14days', '1month', '6month'];

const ProductMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "State", id: 'state', columnType: 'badge', options: [
                {value: 'NEW', style: "primary"},
                {value: 'ACTIVE', style: "success"},
                {value: 'INACTIVE', style: "warning"},
                {value: 'TAKEDOWN', style: "danger"}
            ],
            show: true},
        {label: "Product Type", id: 'productType', columnType: 'badge', options: [
                {value: 'TIGERBOOK', style: "primary"},
                {value: 'MOVIE', style: "info"},
                {value: 'AUDIOBOOK', style: "light", text: "dark"},
                {value: 'BOOK', style: "success"},
                {value: 'SUBSCRIPTION', style: "dark"}
            ],
            show: true},
        {label: "Title", id: 'title', columnType: 'default', show: true},
        {label: "IAP Product Identifier", id: 'iapProductIdentifier', columnType: 'default', show: false},
        {label: "Publication Date", id: 'publicationDate', columnType: 'default', show: false},
        {label: "Freemium", id: 'freemium', columnType: 'boolean', show: false},
        {label: "Automatic Release", id: 'automaticRelease', columnType: 'boolean', show: false},
        {label: "Age Min", id: 'ageMin', columnType: 'default', show: false, className: "text-right"},
        {label: "Author", id: 'author', columnType: 'default', show: false},
        {label: "Last Modified At", id: 'lastModifiedDate', columnType: 'default', show: false},
        {label: "Popularity", id: 'popularity', columnType: 'default', show: false, className: "text-right"},
        {label: "Classification", id: 'productClassification', columnType: 'default', show: false},

        //Custom
        {label: "Illustrator", id: 'illustrator', columnType: 'customValue', show: false},
        {label: "Language", id: 'language', columnType: 'default', show: false},
        {label: "ISBN", id: 'isbn', columnType: 'customValue', show: false},
        {label: "Length", id: 'length', columnType: 'customValue', show: false, className: "text-right text-monospace"},
        {label: "Cover URL", id: 'coverUrl', columnType: 'customValue', show: false},
        {label: "Product Groups", id: 'productGroups', columnType: 'customValue', show: false},

        //Image
        {label: "Cover", id: 'cover', columnType: 'image', show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'number', editable: 'never'},
            {label: 'Last Modified Date', id: 'lastModifiedDate', type: 'datetime', editable: 'never'},
            {label: 'Publication Date', id: 'publicationDate', type: 'date', editable: 'true'},
            {label: 'Automatic Release', id: 'automaticRelease', type: 'boolean', editable: 'true'}],
        [{label: 'Product Type', id: 'productType', type: 'select', options: PRODUCT_TYPES, editable: 'new', trackChanges: true, required: true}, {label: 'Classification', id: 'productClassification', type: 'select', options: PRODUCT_CLASSIFICATIONS, editable: 'true', required: true}, {label: 'State', id: 'state', type: 'select', options: PRODUCT_STATES, editable: 'new', required: true}]
    ],
    DETAILS_CONTENT: [
        [{label: 'Title', id: 'title', type: 'text', editable: 'true', required: true}],
        [{label: 'Description', id: 'description', type: 'textarea', rows: 10, editable: 'true'}],
        [{label: 'Author', id: 'author', type: 'text', editable: 'true'}, {label: 'Illustrator', id: 'illustrator', type: 'text', editable: 'true'}],
        [{label: 'Author Vita', id: 'authorVita', type: 'textarea', editable: 'true', isExpertField: true}],
        [{label: 'Last Name Of First Author', id: 'lastNameOfFirstAuthor', type: 'text', editable: 'true', isExpertField: true}, {label: 'Author Client Number', id: 'authorClientNumber', type: 'text', editable: 'true', isExpertField: true}],
    ],

    //Attributes
    PRODUCT_STATES: PRODUCT_STATES,
    PRODUCT_TYPES: PRODUCT_TYPES,
    PRODUCT_CLASSIFICATIONS: PRODUCT_CLASSIFICATIONS,
    ASSET_PRODUCT_TYPES: ['SCREENSHOT', 'COVER', 'COVER_SMALL', 'PREVIEW', 'CONTENT', 'HLS'],
    ASSET_TYPES: ['IMAGE', 'BINARY', 'AUDIO', 'VIDEO', 'HLS'],
    ASSET_VISIBILITY: ['PROTECTED', 'PUBLIC'],

    //Sorting
    PRODUCT_SORT_PARAMS: [{id: 'id', label: 'Product ID'},
        {id: 'lastModifiedDate', label: 'Updated'}, {id: 'title', label: 'Title'},
        {id: 'publicationDate', label: 'Publication Date'},
        {id: 'popularity', label: 'Popularity'},
        {id: ['_embedded.series.id','_embedded.season.section','_embedded.episodes.seriesIndex'], label: 'Series/Season/Episode'},
    ],

    //IAP Product identifier validation
    ALLOWED_TIGERTONES_SUBSCRIPTION_DURATIONS: ALLOWED_TIGERTONES_SUBSCRIPTION_DURATIONS,
    ALLOWED_TIGERTONES_TIGERTICKET_DURATIONS: ALLOWED_TIGERTONES_TIGERTICKET_DURATIONS,
    ALLOWED_TIGERBOOK_SUBSCRIPTION_DURATIONS: ALLOWED_TIGERBOOK_SUBSCRIPTION_DURATIONS,
    ALLOWED_TIGERBOOKS_SUBSCRIPTION_DURATIONS: ALLOWED_TIGERBOOKS_SUBSCRIPTION_DURATIONS,

};

export default ProductMetaData;

export function ProductStateBadges(product) {
    if(product.state === "NEW") {
        return "primary";
    } else if(product.state === "ACTIVE") {
        return "success";
    } else if(product.state === "INACTIVE") {
        return "warning";
    } else {
        return "danger";
    }
}

export function ProductTypeBadges(product) {
    if(product.productType === "TIGERBOOK") {
        return "primary";
    } else if(product.productType === "MOVIE") {
        return "info";
    } else if(product.productType === "AUDIOBOOK") {
        return "light";
    } else if(product.productType === "BOOK") {
        return "success";
    } else {
        return "dark";
    }
}

export function listOfValidIapProductIdentifier(){
    let list = [];
    ALLOWED_TIGERTONES_SUBSCRIPTION_DURATIONS.forEach(dur => list.push('tigertones.subscription.' + dur));
    ALLOWED_TIGERTONES_TIGERTICKET_DURATIONS.forEach(dur => list.push('tigertones.tigerticket.' + dur));
    ALLOWED_TIGERBOOK_SUBSCRIPTION_DURATIONS.forEach(dur => list.push('tigerbook.subscription.' + dur));
    ALLOWED_TIGERBOOKS_SUBSCRIPTION_DURATIONS.forEach(dur => list.push('tigerbooks.subscription.' + dur));

    return list;
}

export function ProductAttributesByType(type) {
    if (type === 'TIGERBOOK') {
        return {illustrator: null, isbn: null, totalPages: null, augmented: false};
    } else if (type === 'MOVIE') {
        return {length: null, screenplay: null, illustrator: null, country: null};
    } else if (type === 'BOOK') {
        return {illustrator: null, isbn: null, totalPages: null};
    } else if (type === 'SUBSCRIPTION') {
        return {duration: null, durationFree: null, productGroupId: null};
    } else {
        return {illustrator: null, length: null, track: null, isbn: null, externalId: null};
    }
}